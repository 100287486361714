import React from "react";
import Rails from 'rails-ujs';
import NewCardUnivaFields from './NewCardUnivaFields.js';
import NewCardAquagatesFields from './NewCardAquagatesFields.js';
import NewCardPayjpFields from './NewCardPayjpFields.js';
import NewCardStripeFields from './NewCardStripeFields.js';
import NewCardSquareFields from './NewCardSquareFields.js';
import NewCardRappFields from './NewCardRappFields.js';
import NewCardRobotPaymentFields from './NewCardRobotPaymentFields.js';
import NewCardBpmFields from './NewCardBpmFields.js';
import ClipLoader from "react-spinners/ClipLoader";
import PropTypes from "prop-types"

class UpdateUserCardForm extends React.Component {
  constructor (props) {
    super(props);

    this.state = {
      cardExpiryMonth: null,
      cardExpiryYear: null,
      cardLast4: null,
      cardBrand: null,
      cardGatewayFid: null,
      cardOnetimeToken: null,
      cardOnetimeKey: null,
      isLoading: false,
      userCardUid: props.userCards[0]?.uid || '',
    }
    this.form = React.createRef()
  }

  render () {
    const {
      univaStoreId,
      aquagatesClientId,
      aquagatesAccessToken,
      aquagatesJpClientId,
      aquagatesJpAccessToken,
      payjpPublicKey,
      stripePublicKey,
      squareApplicationId,
      squareLocationId,
      payjpDigitalPublicKey,
      rappPublicKey,
      robotPaymentStoreId,
      bpmApiToken,
      requireTds,
      gateway,
      submitPath,
      currentUserUid,
      email,
      tel,
      userCards,
    } = this.props;

    const {
      cardExpiryMonth, cardExpiryYear, cardLast4, cardBrand,
      cardGatewayFid, cardOnetimeToken, cardOnetimeKey,
      userCardUid, isLoading
    } = this.state;

    return(
      <div id="update-user-card-form" className="update-user-card-form">

        <div className="form-group">
          <label>使用するカードを選択</label>
          <select
            className="form-control form-control-lg"
            onChange={e => this.setState({userCardUid: e.target.value})}
            value={userCardUid}
          >
            {userCards.map(card => <option value={card.uid} key={card.uid}>
              XXXX-XXXX-XXXX-{card.last4} ({card.expiryMonth}/{card.expiryYear})
            </option>)}
            <option value="">新しいカード</option>
          </select>
        </div>

        {userCardUid === '' && gateway === 'univa' && <NewCardUnivaFields
          univaStoreId={univaStoreId}
          currentUserUid={currentUserUid}
          email={email}
          tel={tel}
          customSubmitBtnLabel='カード情報を更新'
          isLoading={this.state.isLoading}
          setNewUserCard={(newAttributes) => {
            this.setState({
              cardGatewayFid: newAttributes.getewayFid,
              cardExpiryMonth: newAttributes.expiryMonth,
              cardExpiryYear: newAttributes.expiryYear,
              cardLast4: newAttributes.last4,
              cardBrand: newAttributes.brand
            }, () => {this.form.current.submit()})
          }}
          setIsLoading={(value) => this.setState({isLoading: value})}
        />}

        {userCardUid === '' && ['aquagates', 'aquagates_jp'].includes(gateway) && <NewCardAquagatesFields
          aquagatesClientId={
            gateway === 'aquagates_jp' ? aquagatesJpClientId : aquagatesClientId
          }
          aquagatesAccessToken={
            gateway === 'aquagates_jp' ? aquagatesJpAccessToken : aquagatesAccessToken
          }
          currentUserUid={currentUserUid}
          email={email}
          tel={tel}
          customSubmitBtnLabel='カード情報を更新'
          isLoading={this.state.isLoading}
          setNewUserCard={(newAttributes) => {
            this.setState({
              cardExpiryMonth: newAttributes.expiryMonth,
              cardExpiryYear: newAttributes.expiryYear,
              cardLast4: newAttributes.last4,
              cardBrand: newAttributes.brand,
              cardOnetimeToken: newAttributes.onetimeToken,
              cardOnetimeKey: newAttributes.onetimeKey
            }, () => {this.form.current.submit()})
          }}
          setIsLoading={(value) => this.setState({isLoading: value})}
        />}

        {userCardUid === '' && ['payjp', 'payjp_digital'].includes(gateway) && <NewCardPayjpFields
          payjpPublicKey={
            gateway === 'payjp_digital' ? payjpDigitalPublicKey : payjpPublicKey
          }
          payzSavePath={
            gateway === 'payjp_digital' ? '/payjp_digital_user_cards' : '/payjp_user_cards'
          }
          currentUserUid={currentUserUid}
          email={email}
          tel={tel}
          customSubmitBtnLabel='カード情報を更新'
          isLoading={this.state.isLoading}
          setNewUserCard={(newAttributes) => {
            this.setState({
              userCardUid: newAttributes.userCardUid
            }, () => {this.form.current.submit()})
          }}
          setIsLoading={(value) => this.setState({isLoading: value})}
          requireTds={requireTds}
        />}

        {userCardUid === '' && gateway === 'stripe' && <NewCardStripeFields
          stripePublicKey={stripePublicKey}
          currentUserUid={currentUserUid}
          email={email}
          tel={tel}
          customSubmitBtnLabel='カード情報を更新'
          isLoading={this.state.isLoading}
          setNewUserCard={(newAttributes) => {
            this.setState({
              userCardUid: newAttributes.userCardUid
            }, () => {this.form.current.submit()})
          }}
          setIsLoading={(value) => this.setState({isLoading: value})}
        />}

        {userCardUid === '' && gateway === 'square' && <NewCardSquareFields
          squareApplicationId={squareApplicationId}
          squareLocationId={squareLocationId}
          currentUserUid={currentUserUid}
          email={email}
          tel={tel}
          customSubmitBtnLabel='カード情報を更新'
          isLoading={this.state.isLoading}
          setNewUserCard={(newAttributes) => {
            this.setState({
              userCardUid: newAttributes.userCardUid
            }, () => {this.form.current.submit()})
          }}
          setIsLoading={(value) => this.setState({isLoading: value})}
        />}

        {userCardUid === '' && gateway === 'rapp' && <NewCardRappFields
          rappPublicKey={rappPublicKey}
          currentUserUid={currentUserUid}
          email={email}
          tel={tel}
          customSubmitBtnLabel='カード情報を更新'
          isLoading={this.state.isLoading}
          setNewUserCard={(newAttributes) => {
            this.setState({
              userCardUid: newAttributes.userCardUid
            }, () => {this.form.current.submit()})
          }}
          setIsLoading={(value) => this.setState({isLoading: value})}
        />}

        {userCardUid === '' && gateway === 'robot_payment' && <NewCardRobotPaymentFields
          robotPaymentStoreId={robotPaymentStoreId}
          requireTds={requireTds}
          currentUserUid={currentUserUid}
          email={email}
          tel={tel}
          customSubmitBtnLabel='カード情報を更新'
          isLoading={this.state.isLoading}
          setNewUserCard={(newAttributes) => {
            this.setState({
              userCardUid: newAttributes.userCardUid
            }, () => {this.form.current.submit()})
          }}
          setIsLoading={(value) => this.setState({isLoading: value})}
          tdsDisplayPrice={0}
        />}

        {userCardUid === '' && gateway === 'bpm' && <NewCardBpmFields
          bpmApiToken={bpmApiToken}
          currentUserUid={currentUserUid}
          email={email}
          tel={tel}
          customSubmitBtnLabel='カード情報を更新'
          isLoading={this.state.isLoading}
          setNewUserCard={(newAttributes) => {
            this.setState({
              userCardUid: newAttributes.userCardUid
            }, () => {this.form.current.submit()})
          }}
          setIsLoading={(value) => this.setState({isLoading: value})}
        />}

        <form ref={this.form} action={submitPath} method="post">
          <input type="hidden" name="authenticity_token" value={Rails.csrfToken()} />
          <input type="hidden" name="member_id" value={currentUserUid} />
          <input type="hidden" name="email" value={email} />
          <input type="hidden" name="tel" value={tel} />

          <input type="hidden" name="gateway" value={gateway} />
          <input type="hidden" name="card_expiry_month" value={!!cardExpiryMonth ? cardExpiryMonth : ''} />
          <input type="hidden" name="card_expiry_year" value={!!cardExpiryYear ? cardExpiryYear : ''} />
          <input type="hidden" name="card_last4" value={!!cardLast4 ? cardLast4 : ''} />
          <input type="hidden" name="card_brand" value={!!cardBrand ? cardBrand : ''} />
          <input type="hidden" name="card_gateway_fid" value={!!cardGatewayFid ? cardGatewayFid : ''} />
          <input type="hidden" name="card_onetime_token" value={!!cardOnetimeToken ? cardOnetimeToken : ''} />
          <input type="hidden" name="card_onetime_key" value={!!cardOnetimeKey ? cardOnetimeKey : ''} />
          <input type="hidden" name="user_card_uid" value={!!userCardUid ? userCardUid : ''} />
        </form>

        {userCardUid !== '' && <div className="d-flex justify-content-between">
          <button
            className="btn btn-lg btn-primary"
            disabled={isLoading}
            onClick={() => {
            this.setState({isLoading: true})
            this.form.current.submit()
            }}
          >
            {isLoading ? 'お待ちください..' : 'カード情報を更新'}
          </button>
        </div>}

        {isLoading && <div className="spinner">
          <ClipLoader
            size={150}
            color={"#123abc"}
            loading={true}
          />
        </div>}
      </div>
    )
  }
}

export default UpdateUserCardForm
