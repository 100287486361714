import React from "react"
import Rails from 'rails-ujs'

class FaxField extends React.Component {
  render () {
    const { 
      fax, setFax, isFaxInvalid 
    } = this.props

    return(
      <div className="form-group row">
        <div className="col-12 col-lg-8">
          <label>FAX</label>
          <input type="tel"
            value={fax}
            onChange={e => {setFax(e.target.value.replace(/[^0-9\+]/g, ''))}}
            placeholder=""
            className={"form-control form-control-lg" + (isFaxInvalid ? ' is-invalid' : '')}
          />
        </div>
      </div>
    )
  }
}

export default FaxField
