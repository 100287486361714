import React from "react";

class ProductCheckBoxes extends React.Component {
  constructor (props) {
    super(props);

    this.state = {
      selectedProductGroupUids: [],
    }
  }

  render () {
    const {
      namespace,
      productGroups,
      products,
    } = this.props;

    const {
      selectedProductGroupUids
    } = this.state;

    const toggleProductGroupUid = (productGroupUid) => {
      if (selectedProductGroupUids.includes(productGroupUid)) {
        this.setState({
          selectedProductGroupUids: selectedProductGroupUids.filter(uid => uid !== productGroupUid)
        });
      } else {
        this.setState({
          selectedProductGroupUids: [...selectedProductGroupUids, productGroupUid]
        });
      }
    }

    const productGroupTitle = (pg) => <h6>
      <a onClick={() => toggleProductGroupUid(pg.uid)}>
        <i className={`fas fa-caret-${selectedProductGroupUids.includes(pg.uid) ? 'down' : 'right'}`}></i>
        {pg.title}
      </a>
    </h6>;

    const productInputFieldsInGroup = (pg) => {
      return(
        products.filter(
          pd => pd.productGroupUid === pg.uid
        ).map(pd => <label key={pd.uid}>
          <input type="checkbox" value={pd.uid} name={`${namespace}[]`} defaultChecked={pd.defaultChecked} />
          {pd.title}
        </label>)
      );
    }

    return(
      <>
        <input type="hidden" name={`${namespace}[]`} value="" />

        {productGroups.map(pg => <div key={pg.uid}>
          {productGroupTitle(pg)}
          <div className={selectedProductGroupUids.includes(pg.uid) ? 'd-block' : 'd-none'}>
            {productInputFieldsInGroup(pg)}
          </div>
        </div>)}
      </>
    );
  }
}

export default ProductCheckBoxes;