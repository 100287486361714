import React from "react";

class ReviewNotes extends React.Component {
  render () {
    const { tdsBackUpUrl } = this.props;

    return (
      <>
        <p className="d-md-none text-90 my-2">
          ※ 決済にはお時間がかかる場合があります。
          決済が完了するまでページを離れずにお待ちください。
          また二重決済の原因となるため、複数回ボタンを押さないでください。
        </p>
        <p className="d-none d-md-block text-90 my-3 text-center">
          ※ 決済にはお時間がかかる場合があります。<br />
          決済が完了するまでページを離れずにお待ちください。<br />
          また二重決済の原因となるため、複数回ボタンを押さないでください。
        </p>
        {tdsBackUpUrl && <p className="d-md-none text-90 my-2">
          カードの確認（3Dセキュア）に失敗する場合は、<a href={tdsBackUpUrl}>こちら</a>より購入を完了してください。
        </p>}
        {tdsBackUpUrl && <p className="d-none d-md-block text-90 my-3 text-center">
          カードの確認（3Dセキュア）に失敗する場合は、<a href={tdsBackUpUrl}>こちら</a>より購入を完了してください。
        </p>}
      </>
    );
  }
}

export default ReviewNotes