import { stack as Menu } from 'react-burger-menu'
import React from "react"

class UserBurgerMenu extends React.Component {
  showSettings (event) {
    event.preventDefault();
  }

  render () {
    // NOTE: You also need to provide styles, see https://github.com/negomi/react-burger-menu#styling
    return (
      <Menu right>
        <a id="me" className="menu-item" href="/">
          <i className="fas fa-home"></i> マイページ
        </a>
        <a id="products" className="menu-item" href="/my/purchases">
          <i className="fas fa-box-open"></i> ご購入済み商品
        </a>
        <a id="subscriptions" className="menu-item" href="/my/subscriptions">
          <i className="fas fa-calendar-check"></i> 定期購読商品
        </a>
        <a id="profile" className="menu-item" href="/my/profile">
          <i className="far fa-user"></i> お客様情報
        </a>
        <a id="sign-out" className="menu-item" href="/user/sign_out" data-method="delete" ref="nofollow">
          <i className="far fa-sign-out"></i> ログアウト
        </a>
      </Menu>
    );
  }
}

export default UserBurgerMenu
