import React from "react"
import Rails from 'rails-ujs'
import AddressFields from './profile_fields/AddressFields.js'

class AddressSubForm extends React.Component {
  constructor (props) {
    super(props);
  
    this.state = {
      zip: props.zip, prefecture: props.prefecture, city: props.city,
      address1: props.address1, address2: props.address2,
    };
  }

  render() {
    const {
      prefectures, zipFieldName, prefectureFieldName,
      cityFieldName, address1FieldName, address2FieldName,
      isZipInvalid, isPrefectureInvalid, isCityInvalid,
      isAddress1Invalid, isAddress2Invalid,
    } = this.props;
    const {
      zip, prefecture, city, address1, address2, 
    } = this.state;

    return(
      <React.Fragment>
        <AddressFields
          prefectures={prefectures}
          zip={zip} prefecture={prefecture} city={city}
          address1={address1} address2={address2}
          setZip={v => this.setState({ zip: v })}
          setPrefecture={v => this.setState({ prefecture: v })}
          setCity={v => this.setState({ city: v })}
          setAddress1={v => this.setState({ address1: v })}
          setAddress2={v => this.setState({ address2: v })}
          isZipInvalid={isZipInvalid}
          isPrefectureInvalid={isPrefectureInvalid}
          isCityInvalid={isCityInvalid}
          isAddress1Invalid={isAddress1Invalid}
          isAddress2Invalid={isAddress2Invalid}
        />
        <input type="hidden" name={zipFieldName} value={zip} />
        <input type="hidden" name={prefectureFieldName} value={prefecture} />
        <input type="hidden" name={cityFieldName} value={city} />
        <input type="hidden" name={address1FieldName} value={address1} />
        <input type="hidden" name={address2FieldName} value={address2} />
      </React.Fragment>
    );
  }
}

export default AddressSubForm;
