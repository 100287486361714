import React from "react";
import qs from 'qs';

class SummaryProductGroupSelector extends React.Component {
  constructor (props) {
    super(props);
  }

  render() {
    const {
      dateFrom,
      dateTill,
      resolution,
      group,
      gateway,
      userCardBrand,
      productGroups,
      productGroupUid,
    } = this.props;

    return(
      <select
        className="form-control"
        value={productGroupUid}
        onChange={(e) => {
          const queryParams = {
            date_from: dateFrom,
            date_till: dateTill,
            resolution: resolution,
            gateway: gateway,
            user_card_brand: userCardBrand,
            group: group,
            product_group_uid: e.target.value,
          }
          window.location.href = `${window.location.pathname}?${qs.stringify(queryParams)}`
        }}
      >
        <option value="">グループ: 限定しない</option>
        {productGroups.map(pg => {
          return(<option value={pg.uid} key={pg.uid}>{pg.title}</option>);
        })}
      </select>
    )
  }
}

export default SummaryProductGroupSelector