import React from "react";
import Rails from 'rails-ujs';
import NewCardUnivaFields from './NewCardUnivaFields.js';
import NewCardAquagatesFields from './NewCardAquagatesFields.js';
import NewCardPayjpFields from './NewCardPayjpFields.js';
import NewCardStripeFields from './NewCardStripeFields.js';
import NewCardSquareFields from './NewCardSquareFields.js';
import NewCardRappFields from './NewCardRappFields.js';
import NewCardRobotPaymentFields from './NewCardRobotPaymentFields.js';
import NewCardBpmFields from './NewCardBpmFields.js';
import ClipLoader from "react-spinners/ClipLoader";
import ReviewNotes from './ReviewNotes.js';
import PropTypes from "prop-types";

class ReviewPurchaseForm extends React.Component {
  constructor (props) {
    super(props);

    this.state = {
      paymentOption: props.paymentOptions[0],
      installmentsCount: (props.installmentOptions.length > 0 ?
        props.installmentOptions[0] : undefined
      ),
      partialPaymentPlanUid: (props.partialPaymentPlans.length > 0 ?
        props.partialPaymentPlans[0].uid : undefined
      ),
      userCardUid: (props.userCards.length > 0 ?
        props.userCards[props.userCards.length-1].uid : ''
      ),
      cardExpiryMonth: null,
      cardExpiryYear: null,
      cardLast4: null,
      cardBrand: null,
      cardGatewayFid: null,
      cardOnetimeToken: null,
      cardOnetimeKey: null,
      isLoading: false,
      quantity: 1,
    }
    this.form = React.createRef()
  }

  render () {
    const {
      gateway,
      submitPath,
      backPath,
      payByBankTransfer,
      paymentOptions,
      installmentOptions,
      partialPaymentPlans,
      userCards,
      univaStoreId,
      aquagatesClientId,
      aquagatesAccessToken,
      aquagatesJpClientId,
      aquagatesJpAccessToken,
      payjpPublicKey,
      stripePublicKey,
      squareApplicationId,
      squareLocationId,
      payjpDigitalPublicKey,
      rappPublicKey,
      robotPaymentStoreId,
      bpmApiToken,
      currentUserUid,
      email,
      tel,
      shippingZip,
      shippingTel,
      shippingPrefecture,
      shippingCity,
      shippingAddress1,
      shippingAddress2,
      nameLast,
      nameFirst,
      zip,
      prefecture,
      city,
      address1,
      address2,
      productType,
      requireName,
      requireAddress,
      requireShippingAddress,
      productImageUrl,
      productPrice,
      productStock,
      productTitle,
      productDescriptionHtml,
      productContactTextHtml,
      isShippingAddressRemote,
      applicableShippingFee,
      requireTds,
      tdsBackUpUrl,
    } = this.props;

    const {
      paymentOption,
      installmentsCount,
      partialPaymentPlanUid,
      userCardUid,
      cardExpiryMonth,
      cardExpiryYear,
      cardLast4,
      cardBrand,
      cardGatewayFid,
      cardOnetimeToken,
      cardOnetimeKey,
      isLoading,
      quantity,
    } = this.state;

    const totalPrice = productPrice * quantity + applicableShippingFee;
    const isQuantityInvalid = Number(quantity) < 1 || (
      productType === 'physical' && Number(quantity) > productStock
    );

    const partialPaymentPlan = (
      paymentOption === 'partial_payments' && !!partialPaymentPlanUid
    ) ? partialPaymentPlans.find(
      plan => plan.uid === partialPaymentPlanUid
    ) : null;

    return (
      <div id="review-purchase-form" className="review-purchase-form">
        <div className="card card-body bg-light mb-3">
          <h4>ご購入商品</h4>
          <div className="media">
            {!!productImageUrl && <img
              className="mr-2"
              src={productImageUrl}
            />}
            <div className="media-body">
              <h4 className="text-100">
                {productType === 'physical' && <div className="float-right text-right" style={{ maxWidth: '50%' }}>
                  {productPrice.toLocaleString()}円
                  <>
                    <div class="row mt-2">
                      <div className="col-12 col-md-5 d-flex justify-content-end align-items-center pr-md-0 mb-2 mb-md-0">
                        <div className="text-85">
                          残り在庫: {productStock.toLocaleString()}
                        </div>
                      </div>
                      <div className="col-12 col-md-7 d-flex justify-content-end">
                        <div className="input-group input-group-sm input-group-quantity">
                          <div className="input-group-prepend">
                            <div className="input-group-text">数量</div>
                          </div>
                          <input
                            className="form-control"
                            required="required"
                            type="number"
                            value={quantity}
                            onChange={e => {
                              if (e.target.value.length > 0 && Number(e.target.value) < 1) {
                                alert('数量は1以上を設定してください');
                              } else if (Number(e.target.value) > productStock) {
                                alert(`残り在庫数以下の数量でのご購入をお願いいたします`);
                              } else {
                                this.setState({ quantity: e.target.value });
                              }
                            }}
                          />
                          <div className="input-group-append">
                            <div className="input-group-text">個</div>
                          </div>
                        </div>
                      </div>
                    </div>

                  </>
                </div>}
                {productTitle}
              </h4>
              <div
                className="text-90 product-description"
                dangerouslySetInnerHTML={{__html: productDescriptionHtml}}
              />
            </div>
          </div>
          <>
            <div className="text-right">
              {productType === 'physical' && <div className="mb-2">
                送料 ({isShippingAddressRemote ? '離島地域' : '通常配送地域'}):{' '}
                {applicableShippingFee > 0 ? `${applicableShippingFee.toLocaleString()}円` : '無料'}
              </div>}
              <div className="text-110">
                合計 (税込): <strong>{totalPrice.toLocaleString()}円</strong>
              </div>
            </div>
          </>
          {!!productContactTextHtml && productContactTextHtml.length > 0 && <>
            <hr />
            <h6>商品に関するお問合せ先</h6>
            <p
              className="mb-0"
              dangerouslySetInnerHTML={{__html: productContactTextHtml}}
            />
          </>}
        </div>

        <div className="row">
          <div className="col-12 col-md-6">
            <h3>ご購入者</h3>
            <p className="ml-1">
              {requireName && <>
                <strong>{nameLast} {nameFirst} 様</strong>
                <br />
              </>}
              {requireAddress && <>
                {zip}
                <br />
                {prefecture}{city}{address1}
                {!!address2 && address2.length > 0 && <>
                  <br />
                  {address2}
                </>}
                <br />
              </>}
              {tel}
              <br />
              {email}
            </p>
          </div>
          {requireShippingAddress && <div className="col-12 col-md-6">
            <h3>お届け先</h3>
            <p className="ml-1">
            <strong>{nameLast} {nameFirst} 様</strong>
              <br />
              {shippingZip}
              <br />
              {shippingPrefecture}{shippingCity}{shippingAddress1}
              {!!shippingAddress2 && shippingAddress2.length > 0 && <>
                <br />
                {shippingAddress2}
              </>}
              <br />
              {shippingTel}
            </p>
          </div>}
        </div>


        <h3>お支払い情報</h3>
        <div className="row">
          <div className="col-12 col-md-10">
            <div className="form-group">
              <label>お支払い方法</label>
              <select
                className="form-control form-control-lg col-md-6" 
                onChange={e => this.setState({paymentOption: e.target.value})}
                value={paymentOption}
              >
                <option value="single">一括払い</option>
                {paymentOptions.includes('installments') && 
                  <option value="installments">分割払い</option>}
                {paymentOptions.includes('partial_payments') && 
                  <option value="partial_payments">分割決済</option>}
              </select>
            </div>
            {paymentOption === 'installments' && <div className="form-group">
              <label>お支払い回数</label>
              <InstallmentsCountSelect
                installmentsCount={installmentsCount}
                installmentOptions={installmentOptions}
                onChange={(e) => this.setState({installmentsCount: e.target.value})}
              />
            </div>}
            {paymentOption === 'partial_payments' && <div className="form-group">
              <label>お支払い回数</label>
              <PartialPaymentPlanSelect
                partialPaymentPlanUid={partialPaymentPlanUid}
                partialPaymentPlans={partialPaymentPlans}
                onChange={(e) => this.setState({partialPaymentPlanUid: e.target.value})}
              />
              {!!partialPaymentPlanUid && <PartialPaymentPlanTable
                partialPaymentPlan={partialPaymentPlan}
              />}
            </div>}

            {!payByBankTransfer && <div className="form-group">
              <label>使用するカードを選択</label>
              <select
                className="form-control form-control-lg"
                onChange={e => this.setState({userCardUid: e.target.value})}
                value={userCardUid}
              >
                {userCards.map(card => <option value={card.uid} key={card.uid}>
                  XXXX-XXXX-XXXX-{card.last4} ({card.expiryMonth}/{card.expiryYear})
                </option>)}
                <option value="">新しいカード</option>
              </select>
            </div>}
          </div>
        </div>

        {userCardUid === '' && gateway === 'univa' && <NewCardUnivaFields
          univaStoreId={univaStoreId}
          currentUserUid={currentUserUid}
          email={email}
          tel={tel}
          isLoading={this.state.isLoading}
          backPath={backPath}
          setNewUserCard={(newAttributes) => {
            this.setState({
              cardGatewayFid: newAttributes.getewayFid,
              cardExpiryMonth: newAttributes.expiryMonth,
              cardExpiryYear: newAttributes.expiryYear,
              cardLast4: newAttributes.last4,
              cardBrand: newAttributes.brand
            }, () => {this.form.current.submit()})
          }}
          setIsLoading={(value) => this.setState({isLoading: value})}
          tdsBackUpUrl={tdsBackUpUrl}
        />}

        {userCardUid === '' && ['aquagates', 'aquagates_jp'].includes(gateway) && <NewCardAquagatesFields
          aquagatesClientId={
            gateway === 'aquagates_jp' ? aquagatesJpClientId : aquagatesClientId
          }
          aquagatesAccessToken={
            gateway === 'aquagates_jp' ? aquagatesJpAccessToken : aquagatesAccessToken
          }
          currentUserUid={currentUserUid}
          email={email}
          tel={tel}
          isLoading={this.state.isLoading}
          backPath={backPath}
          setNewUserCard={(newAttributes) => {
            this.setState({
              cardExpiryMonth: newAttributes.expiryMonth,
              cardExpiryYear: newAttributes.expiryYear,
              cardLast4: newAttributes.last4,
              cardBrand: newAttributes.brand,
              cardOnetimeToken: newAttributes.onetimeToken,
              cardOnetimeKey: newAttributes.onetimeKey
            }, () => {this.form.current.submit()})
          }}
          setIsLoading={(value) => this.setState({isLoading: value})}
          tdsBackUpUrl={tdsBackUpUrl}
        />}

        {userCardUid === '' && ['payjp', 'payjp_digital'].includes(gateway) && <NewCardPayjpFields
          payjpPublicKey={
            gateway === 'payjp_digital' ? payjpDigitalPublicKey : payjpPublicKey
          }
          payzSavePath={
            gateway === 'payjp_digital' ? '/payjp_digital_user_cards' : '/payjp_user_cards'
          }
          currentUserUid={currentUserUid}
          email={email}
          tel={tel}
          isLoading={this.state.isLoading}
          backPath={backPath}
          setNewUserCard={(newAttributes) => {
            this.setState({
              userCardUid: newAttributes.userCardUid
            }, () => {this.form.current.submit()})
          }}
          setIsLoading={(value) => this.setState({isLoading: value})}
          tdsBackUpUrl={tdsBackUpUrl}
          requireTds={requireTds}
        />}

        {userCardUid === '' && gateway === 'stripe' && <NewCardStripeFields
          stripePublicKey={stripePublicKey}
          currentUserUid={currentUserUid}
          email={email}
          tel={tel}
          isLoading={this.state.isLoading}
          backPath={backPath}
          setNewUserCard={(newAttributes) => {
            this.setState({
              userCardUid: newAttributes.userCardUid
            }, () => {this.form.current.submit()})
          }}
          setIsLoading={(value) => this.setState({isLoading: value})}
          tdsBackUpUrl={tdsBackUpUrl}
        />}

        {userCardUid === '' && gateway === 'square' && <NewCardSquareFields
          squareApplicationId={squareApplicationId}
          squareLocationId={squareLocationId}
          currentUserUid={currentUserUid}
          email={email}
          tel={tel}
          isLoading={this.state.isLoading}
          backPath={backPath}
          setNewUserCard={(newAttributes) => {
            this.setState({
              userCardUid: newAttributes.userCardUid
            }, () => {this.form.current.submit()})
          }}
          setIsLoading={(value) => this.setState({isLoading: value})}
          tdsBackUpUrl={tdsBackUpUrl}
        />}

        {userCardUid === '' && gateway === 'rapp' && <NewCardRappFields
          rappPublicKey={rappPublicKey}
          email={email}
          isLoading={this.state.isLoading}
          backPath={backPath}
          setNewUserCard={(newAttributes) => {
            this.setState({
              userCardUid: newAttributes.userCardUid
            }, () => {this.form.current.submit()})
          }}
          setIsLoading={(value) => this.setState({isLoading: value})}
        />}

        {userCardUid === '' && gateway === 'robot_payment' && <NewCardRobotPaymentFields
          robotPaymentStoreId={robotPaymentStoreId}
          isLoading={this.state.isLoading}
          backPath={backPath}
          setNewUserCard={(newAttributes) => {
            this.setState({
              userCardUid: newAttributes.userCardUid
            }, () => {this.form.current.submit()})
          }}
          setIsLoading={(value) => this.setState({isLoading: value})}
          requireTds={requireTds}
          tdsBackUpUrl={tdsBackUpUrl}
          // 実際には有効性確認だけで、あくまでも3DS表示用。partialPaymentPlanがある場合はデジタル商品なので送料は無視
          tdsDisplayPrice={partialPaymentPlan?.payments?.at(0)?.amount ?? totalPrice}
        />}

        {userCardUid === '' && gateway === 'bpm' && <NewCardBpmFields
          bpmApiToken={bpmApiToken}
          isLoading={this.state.isLoading}
          backPath={backPath}
          setNewUserCard={(newAttributes) => {
            this.setState({
              userCardUid: newAttributes.userCardUid
            }, () => {this.form.current.submit()})
          }}
          setIsLoading={(value) => this.setState({isLoading: value})}
        />}

        <form ref={this.form} action={submitPath} method="post">
          <input type="hidden" name="authenticity_token" value={Rails.csrfToken()} />
          <input type="hidden" name="member_id" value={currentUserUid} />
          <input type="hidden" name="email" value={email} />
          <input type="hidden" name="tel" value={tel} />

          <input type="hidden" name="shipping_zip" value={!!shippingZip ? shippingZip : ''} />
          <input type="hidden" name="shipping_prefecture" value={!!shippingPrefecture ? shippingPrefecture : ''} />
          <input type="hidden" name="shipping_city" value={!!shippingCity ? shippingCity : ''} />
          <input type="hidden" name="shipping_address1" value={!!shippingAddress1 ? shippingAddress1 : ''} />
          <input type="hidden" name="shipping_address2" value={!!shippingAddress2 ? shippingAddress2 : ''} />
          <input type="hidden" name="shipping_tel" value={!!shippingTel ? shippingTel : ''} />

          <input type="hidden" name="gateway" value={gateway} />
          <input type="hidden" name="user_card_uid" value={!!userCardUid ? userCardUid : ''} />
          <input type="hidden" name="card_expiry_month" value={!!cardExpiryMonth ? cardExpiryMonth : ''} />
          <input type="hidden" name="card_expiry_year" value={!!cardExpiryYear ? cardExpiryYear : ''} />
          <input type="hidden" name="card_last4" value={!!cardLast4 ? cardLast4 : ''} />
          <input type="hidden" name="card_brand" value={!!cardBrand ? cardBrand : ''} />
          <input type="hidden" name="card_gateway_fid" value={!!cardGatewayFid ? cardGatewayFid : ''} />
          <input type="hidden" name="card_onetime_token" value={!!cardOnetimeToken ? cardOnetimeToken : ''} />
          <input type="hidden" name="card_onetime_key" value={!!cardOnetimeKey ? cardOnetimeKey : ''} />
          <input type="hidden" name="payment_option" value={paymentOption} />
          <input type="hidden" name="installments_count" value={paymentOption === 'installments' ? installmentsCount : ''} />
          <input type="hidden" name="partial_payment_plan_uid" value={partialPaymentPlanUid} />
          <input type="hidden" name="quantity" value={quantity} />
          <input type="hidden" name="is_shipping_address_remote" value={isShippingAddressRemote ? '1' : '0'} />
        </form>

        {(userCardUid !== '' || payByBankTransfer) && <ReviewNotes tdsBackUpUrl={tdsBackUpUrl} />}

        {(userCardUid !== '' || payByBankTransfer) && <div className="d-flex justify-content-between">
          <a className="btn btn-lg btn-outline-secondary" href={backPath} disabled={isLoading}>戻る</a>
          <button
            className="btn btn-lg btn-primary"
            disabled={isLoading || isQuantityInvalid}
            onClick={() => {
            this.setState({isLoading: true})
            this.form.current.submit()
            }}
          >
            {isLoading ? 'お待ちください..' : '商品をご購入'}
          </button>
        </div>}

        {isLoading && <div className="spinner">
          <ClipLoader
            size={150}
            color={"#123abc"}
            loading={true}
          />
        </div>}
      </div>
    );
  }
}

class InstallmentsCountSelect extends React.Component {
  render() {
    const {
      installmentsCount,
      installmentOptions,
      onChange
    } = this.props;

    return(
      <select
        className="form-control form-control-lg col-md-6"
        onChange={onChange} value={installmentsCount}
      >
        {installmentOptions.map(
          n => <option value={n} key={n}>{n}回払い</option>
        )}
      </select>
    );
  }
}

class PartialPaymentPlanSelect extends React.Component {
  render() {
    const {
      partialPaymentPlanUid,
      partialPaymentPlans,
      onChange
    } = this.props;

    return(
      <select
        className="form-control form-control-lg col-md-6"
        onChange={onChange} value={partialPaymentPlanUid}
      >
        {partialPaymentPlans.map(
          plan => <option value={plan.uid} key={plan.uid}>{plan.label}</option>
        )}
      </select>
    );
  }
}

class PartialPaymentPlanTable extends React.Component {
  render() {
    const {
      partialPaymentPlan
    } = this.props;

    if(!!!partialPaymentPlan){
      return(<React.Fragment></React.Fragment>);
    }

    return(
      <table className="table table-sm table-bordered mt-2">
        <tbody>
          {partialPaymentPlan.payments.map((payment, i) => <tr key={i}>
            <th scope="row" className="">{payment.label}</th>
            <td className="text-center">{payment.formattedAmount}</td>
          </tr>)}
        </tbody>
      </table>
    );
  }
}

export default ReviewPurchaseForm
