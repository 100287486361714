import React from "react"
import AsyncSelect from 'react-select/async'
import axios from 'axios'

const userToOption = (user) => (
  { value: user.uid, label: user.label }
);

const fetchMatchedUsers = async (inputValue, callback) => {
  try {
    const response = await axios.get('/admin/api/users');
    callback(
      response.data.users.map(userToOption)
    )
  } catch (error) {
    console.error(error);
  }
}

class UserSelectField extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      selectedUserOption: !!props.user ? userToOption(props.user) : undefined
    }
  }

  render() {
    const { fieldName, placeholder, required } = this.props;
    const { selectedUserOption } = this.state;

    return(
      <React.Fragment>
        <AsyncSelect
          loadOptions={fetchMatchedUsers}
          defaultOptions
          onChange={(e) => {this.setState({ selectedUserOption: e })}}
          placeholder={placeholder}
          required
        />
        <input 
          name={fieldName} 
          style={{ opacity: 0, height: 0, position: 'absolute', left: '30px' }} 
          required={!!required}
          onChange={() => {}}
          value={
          !!selectedUserOption ? selectedUserOption.value : ''
        } />
      </React.Fragment>
    )
  }
}

export default UserSelectField