import React from "react"
import Rails from 'rails-ujs'
import moment from 'moment'
import NameFields from './profile_fields/NameFields.js'
import KanaFields from './profile_fields/KanaFields.js'
import AddressFields from './profile_fields/AddressFields.js'
import EmailField from './profile_fields/EmailField.js'
import TelField from './profile_fields/TelField.js'
import FaxField from './profile_fields/FaxField.js'
import DobFields from './profile_fields/DobFields.js'
import ClipLoader from "react-spinners/ClipLoader"

class CheckoutForm extends React.Component {
  constructor (props) {
    super(props);

    this.state = {
      email: props.email,
      tel: props.tel,
      fax: props.fax,
      password: '',
      nameLast: props.nameLast,
      nameFirst: props.nameFirst,
      kanaLast: props.kanaLast,
      kanaFirst: props.kanaFirst,
      dobYear: props.dobYear,
      dobMonth: props.dobMonth,
      dobDay: props.dobDay,
      zip: props.zip,
      prefecture: props.prefecture,
      city: props.city,
      address1: props.address1,
      address2: props.address2,
      isAddressRemote: props.isAddressRemote,
      copyShippingAddress: props.copyShippingAddress,
      shippingZip: props.shippingZip,
      shippingPrefecture: props.shippingPrefecture,
      shippingCity: props.shippingCity,
      shippingAddress1: props.shippingAddress1,
      shippingAddress2: props.shippingAddress2,
      shippingTel: props.shippingTel,
      isShippingAddressRemote: props.isShippingAddressRemote,
      isEmailInvalid: false,
      isTelInvalid: false,
      isFaxInvalid: false,
      isPasswordInvalid: false,
      isNameLastInvalid: false,
      isNameFirstInvalid: false,
      isKanaLastInvalid: false,
      isKanaFirstInvalid: false,
      isDobYearInvalid: false,
      isDobMonthInvalid: false,
      isDobDayInvalid: false,
      isZipInvalid: false,
      isPrefectureInvalid: false,
      isCityInvalid: false,
      isAddress1Invalid: false,
      isAddress2Invalid: false,
      isShippingZipInvalid: false,
      isShippingPrefectureInvalid: false,
      isShippingCityInvalid: false,
      isShippingAddress1Invalid: false,
      isShippingAddress2Invalid: false,
      isShippingTelInvalid: false,
      isLoading: false,
      isValidating: false,
    }
    this.validate = this.validate.bind(this)
    this.form = React.createRef()
  }

  validate () {
    let result = true;
    const {
      isUserSignedIn,
      requireName,
      requireKana,
      requireFax,
      requireDob,
      requireAddress,
      requireShippingAddress,
    } = this.props;

    const {
      email,
      tel,
      fax,
      password,
      nameLast,
      nameFirst,
      kanaLast,
      kanaFirst,
      dobYear,
      dobMonth,
      dobDay,
      zip,
      prefecture,
      city,
      address1,
      copyShippingAddress,
      shippingZip,
      shippingPrefecture,
      shippingCity,
      shippingAddress1,
      shippingTel,
    } = this.state;

    if(email.length === 0) {
      this.setState({isEmailInvalid: true})
      result = false;
    } else {
      this.setState({isEmailInvalid: false})
    }
    if(tel.length < 8 || tel.length > 12) {
      this.setState({isTelInvalid: true})
      result = false;
    } else {
      this.setState({isTelInvalid: false})
    }
    if(requireFax && (fax.length < 8 || fax.length > 12)) {
      this.setState({isFaxInvalid: true})
      result = false;
    } else {
      this.setState({isFaxInvalid: false})
    }
    if(!isUserSignedIn && password.length < 6){
      this.setState({isPasswordInvalid: true})
      result = false;
    } else {
      this.setState({isPasswordInvalid: false})
    }
    if(requireName && nameLast.length === 0) {
      this.setState({isNameLastInvalid: true})
      result = false;
    } else {
      this.setState({isNameLastInvalid: false})
    }
    if(requireName && nameFirst.length === 0) {
      this.setState({isNameFirstInvalid: true})
      result = false;
    } else {
      this.setState({isNameFirstInvalid: false})
    }
    if(requireKana && kanaLast.length === 0) {
      this.setState({isKanaLastInvalid: true})
      result = false;
    } else {
      this.setState({isKanaLastInvalid: false})
    }
    if(requireKana && kanaFirst.length === 0) {
      this.setState({isKanaFirstInvalid: true})
      result = false;
    } else {
      this.setState({isKanaFirstInvalid: false})
    }
    if(requireDob) {
      const isDobValidMoment = moment(`${dobYear}-${dobMonth}-${dobDay}`, "YYYY-MM-DD").isValid()
      if(!dobYear || dobYear.length === 0 || !isDobValidMoment) {
        this.setState({isDobYearInvalid: true})
        result = false;
      } else {
        this.setState({isDobYearInvalid: false})
      }
      if(!dobMonth || dobMonth.length === 0 || !isDobValidMoment) {
        this.setState({isDobMonthInvalid: true})
        result = false;
      } else {
        this.setState({isDobMonthInvalid: false})
      }
      if(!dobDay || dobDay.length === 0 || !isDobValidMoment) {
        this.setState({isDobDayInvalid: true})
        result = false;
      } else {
        this.setState({isDobDayInvalid: false})
      }
    }

    if(requireAddress && (zip.length < 5 || zip.length > 8)) {
      this.setState({isZipInvalid: true})
      result = false;
    } else {
      this.setState({isZipInvalid: false})
    }
    if(requireAddress && prefecture.length === 0) {
      this.setState({isPrefectureInvalid: true})
      result = false;
    } else {
      this.setState({isPrefectureInvalid: false})
    }
    if(requireAddress && city.length === 0) {
      this.setState({isCityInvalid: true})
      result = false;
    } else {
      this.setState({isCityInvalid: false})
    }
    if(requireAddress && address1.length === 0) {
      this.setState({isAddress1Invalid: true})
      result = false;
    } else {
      this.setState({isAddress1Invalid: false})
    }

    if(requireShippingAddress && !copyShippingAddress) {
      if(shippingZip.length < 5 || shippingZip.length > 8) {
        this.setState({isShippingZipInvalid: true})
        result = false;
      } else {
        this.setState({isShippingZipInvalid: false})
      }
      if(shippingPrefecture.length === 0) {
        this.setState({isShippingPrefectureInvalid: true})
        result = false;
      } else {
        this.setState({isShippingPrefectureInvalid: false})
      }
      if(shippingCity.length === 0) {
        this.setState({isShippingCityInvalid: true})
        result = false;
      } else {
        this.setState({isShippingCityInvalid: false})
      }
      if(shippingAddress1.length === 0) {
        this.setState({isShippingAddress1Invalid: true})
        result = false;
      } else {
        this.setState({isShippingAddress1Invalid: false})
      }
      if(shippingTel.length === 0) {
        this.setState({isShippingTelInvalid: true})
        result = false;
      } else {
        this.setState({isShippingTelInvalid: false})
      }
    }

    return result;
  }

  render() {
    const {
      submitPath,
      prefectures,
      isUserSignedIn,
      requireName,
      requireKana,
      requireFax,
      requireDob,
      requireAddress,
      requireShippingAddress,
      isDisabled,
    } = this.props

    const {
      email,
      tel,
      fax,
      nameLast,
      nameFirst,
      kanaLast,
      kanaFirst,
      dobYear,
      dobMonth,
      dobDay,
      zip,
      prefecture,
      city,
      address1,
      address2,
      isAddressRemote,
      copyShippingAddress,
      shippingZip,
      shippingPrefecture,
      shippingCity,
      shippingAddress1,
      shippingAddress2,
      shippingTel,
      isShippingAddressRemote,
      isLoading,
      isValidating,
      isEmailInvalid,
      isTelInvalid,
      isFaxInvalid,
      isPasswordInvalid,
      isNameLastInvalid,
      isNameFirstInvalid,
      isKanaLastInvalid,
      isKanaFirstInvalid,
      isDobYearInvalid,
      isDobMonthInvalid,
      isDobDayInvalid,
      isZipInvalid,
      isPrefectureInvalid,
      isCityInvalid,
      isAddress1Invalid,
      isAddress2Invalid,
      isShippingZipInvalid,
      isShippingPrefectureInvalid,
      isShippingCityInvalid,
      isShippingAddress1Invalid,
      isShippingAddress2Invalid,
      isShippingTelInvalid,
    } = this.state

    const afterUpdate = () => {isValidating && this.validate()}

    return(
      <form ref={this.form} action={submitPath} method="post" className="checkout-form">
        <input type="hidden" name="authenticity_token" value={Rails.csrfToken()} />

        <EmailField
          email={email}
          setEmail={v => this.setState({ email: v }, afterUpdate)}
          isReadOnly={isUserSignedIn}
          isEmailInvalid={isEmailInvalid}
        />

        {requireName && <NameFields
          nameLast={nameLast} nameFirst={nameFirst}
          setNameLast={v => this.setState({ nameLast: v }, afterUpdate)}
          setNameFirst={v => this.setState({ nameFirst: v }, afterUpdate)}
          isNameLastInvalid={isNameLastInvalid}
          isNameFirstInvalid={isNameFirstInvalid}
        />}

        {requireKana && <KanaFields
          kanaLast={kanaLast} kanaFirst={kanaFirst}
          setKanaLast={v => this.setState({ kanaLast: v }, afterUpdate)}
          setKanaFirst={v => this.setState({ kanaFirst: v }, afterUpdate)}
          isKanaLastInvalid={isKanaLastInvalid}
          isKanaFirstInvalid={isKanaFirstInvalid}
        />}

        {requireAddress && <AddressFields
          prefectures={prefectures}
          zip={zip} prefecture={prefecture} city={city}
          address1={address1} address2={address2}
          isRemote={isAddressRemote}
          setZip={v => this.setState({ zip: v }, afterUpdate)}
          setPrefecture={v => this.setState({ prefecture: v }, afterUpdate)}
          setCity={v => this.setState({ city: v }, afterUpdate)}
          setAddress1={v => this.setState({ address1: v }, afterUpdate)}
          setAddress2={v => this.setState({ address2: v }, afterUpdate)}
          setIsRemote={v => this.setState({ isAddressRemote: !isAddressRemote, afterUpdate})}
          isZipInvalid={isZipInvalid}
          isPrefectureInvalid={isPrefectureInvalid}
          isCityInvalid={isCityInvalid}
          isAddress1Invalid={isAddress1Invalid}
          isAddress2Invalid={isAddress2Invalid}
        />}

        <TelField
          tel={tel}
          setTel={v => this.setState({ tel: v }, afterUpdate)}
          isTelInvalid={isTelInvalid}
        />

        {requireFax && <FaxField
          fax={fax}
          setFax={v => this.setState({ fax: v }, afterUpdate)}
          isFaxInvalid={isFaxInvalid}
        />}

        {requireDob && <DobFields
          year={dobYear} month={dobMonth} day={dobDay}
          setYear={v => this.setState({ dobYear: v }, afterUpdate)}
          setMonth={v => this.setState({ dobMonth: v }, afterUpdate)}
          setDay={v => this.setState({ dobDay: v }, afterUpdate)}
          isYearInvalid={isDobYearInvalid}
          isMonthInvalid={isDobMonthInvalid}
          isDayInvalid={isDobDayInvalid}
        />}

        {requireShippingAddress && <React.Fragment>
          <hr />
          <h4>お届け先住所</h4>
          {requireAddress && <div className="form-group">
            <div className="form-check">
              <label>
                <input type="checkbox"
                  className="form-check-input"
                  checked={copyShippingAddress}
                  onChange={e => this.setState({ copyShippingAddress: !copyShippingAddress }, afterUpdate)} 
                />
                配送先住所をお客様情報の住所と同じものにする
              </label>
            </div>
          </div>}
          {!copyShippingAddress && <AddressFields
            prefectures={prefectures}
            zip={shippingZip} prefecture={shippingPrefecture} city={shippingCity}
            address1={shippingAddress1} address2={shippingAddress2}
            isRemote={isShippingAddressRemote}
            setZip={v => this.setState({ shippingZip: v }, afterUpdate)}
            setPrefecture={v => this.setState({ shippingPrefecture: v }, afterUpdate)}
            setCity={v => this.setState({ shippingCity: v }, afterUpdate)}
            setAddress1={v => this.setState({ shippingAddress1: v }, afterUpdate)}
            setAddress2={v => this.setState({ shippingAddress2: v }, afterUpdate)}
            setIsRemote={v => this.setState({ isShippingAddressRemote: !isShippingAddressRemote, afterUpdate})}
            isZipInvalid={isShippingZipInvalid}
            isPrefectureInvalid={isShippingPrefectureInvalid}
            isCityInvalid={isShippingCityInvalid}
            isAddress1Invalid={isShippingAddress1Invalid}
            isAddress2Invalid={isShippingAddress2Invalid}
          />}
          {!copyShippingAddress && <TelField
            tel={shippingTel}
            setTel={v => this.setState({ shippingTel: v }, afterUpdate)}
            isTelInvalid={isShippingTelInvalid}
          />}
        </React.Fragment>}

        {!isUserSignedIn && <React.Fragment>
          <hr />
          <div className="form-group row">
            <div className="col-12 col-lg-6">
              <label>アカウントに設定するパスワード</label>
              <input type="password" name="password"
                className={"form-control form-control-lg" + (isPasswordInvalid ? ' is-invalid' : '')}
                onChange={e => this.setState({ password: e.target.value })}
              />
              <p className="help-text">半角英数字記号6字以上で入力してください。</p>
            </div>
          </div>
        </React.Fragment>}

        <input type="hidden" name="email" value={email} />
        <input type="hidden" name="tel" value={tel} />
        <input type="hidden" name="fax" value={fax} />
        <input type="hidden" name="name_last" value={nameLast} />
        <input type="hidden" name="name_first" value={nameFirst} />
        <input type="hidden" name="kana_last" value={kanaLast} />
        <input type="hidden" name="kana_first" value={kanaFirst} />

        <input type="hidden" name="dob[year]" value={dobYear} />
        <input type="hidden" name="dob[month]" value={dobMonth} />
        <input type="hidden" name="dob[day]" value={dobDay} />

        <input type="hidden" name="zip" value={zip} />
        <input type="hidden" name="prefecture" value={prefecture} />
        <input type="hidden" name="city" value={city} />
        <input type="hidden" name="address1" value={address1} />
        <input type="hidden" name="address2" value={address2} />
        <input type="hidden" name="is_address_remote" value={isAddressRemote ? '1' : '0'} />

        <input type="hidden" name="copy_shipping_address" value={copyShippingAddress ? '1' : '0'} />

        {copyShippingAddress ? <React.Fragment>
          <input type="hidden" name="shipping_zip" value={zip} />
          <input type="hidden" name="shipping_prefecture" value={prefecture} />
          <input type="hidden" name="shipping_city" value={city} />
          <input type="hidden" name="shipping_address1" value={address1} />
          <input type="hidden" name="shipping_address2" value={address2} />
          <input type="hidden" name="shipping_tel" value={tel} />
          <input type="hidden" name="is_shipping_address_remote" value={isAddressRemote ? '1' : '0'} />
        </React.Fragment> : <React.Fragment>
          <input type="hidden" name="shipping_zip" value={shippingZip} />
          <input type="hidden" name="shipping_prefecture" value={shippingPrefecture} />
          <input type="hidden" name="shipping_city" value={shippingCity} />
          <input type="hidden" name="shipping_address1" value={shippingAddress1} />
          <input type="hidden" name="shipping_address2" value={shippingAddress2} />
          <input type="hidden" name="shipping_tel" value={shippingTel} />
          <input type="hidden" name="is_shipping_address_remote" value={isShippingAddressRemote ? '1' : '0'} />
        </React.Fragment>}

        <div className="text-center pt-3 pb-5">
          <button className="btn btn-lg btn-primary" disabled={isLoading || isDisabled} onClick={(e) => {
            e.preventDefault()
            this.setState({ isLoading: true, isValidating: true }, () => {
              if(this.validate()) {
                this.form.current.submit()
              } else {
                this.setState({ isLoading: false })
              }
            })
          }}>
            {isLoading ? '処理中です' : '次のステップ'}
          </button>
        </div>

        {isLoading && <div className="spinner">
          <ClipLoader
            size={150}
            color={"#123abc"}
            loading={true}
          />
        </div>}
      </form>
    )
  }
}

export default CheckoutForm;
