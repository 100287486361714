import React from "react"
import Rails from 'rails-ujs'

class KanaFields extends React.Component {
  render () {
    const { 
      kanaLast, kanaFirst, setKanaLast, setKanaFirst, 
      isKanaLastInvalid, isKanaFirstInvalid 
    } = this.props

    return(
      <div className="form-group row">
        <div className="col-6 pr-0 pr-md-2">
          <label>ふりがな（せい）</label>
          <input type="text"
            value={kanaLast}
            onChange={e => {setKanaLast(e.target.value)}}
            placeholder=""
            className={"form-control form-control-lg" + (isKanaLastInvalid ? ' is-invalid' : '')}
          />
        </div>
        <div className="col-6 pl-0 pl-md-2">
          <label>（めい）</label>
          <input type="text"
            value={kanaFirst}
            onChange={e => {setKanaFirst(e.target.value)}}
            placeholder=""
            className={"form-control form-control-lg" + (isKanaFirstInvalid ? ' is-invalid' : '')}
          />
        </div>
      </div>
    )
  }
}

export default KanaFields
