// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

// Polyfill
require('react-app-polyfill/ie9')
require('react-app-polyfill/stable')
require('babel-polyfill')
require("bootstrap.native/dist/polyfill")

require("@rails/ujs").start()
//require("turbolinks").start()
require("@rails/activestorage").start()
require("channels")
require("bootstrap.native/dist/bootstrap-native-v4")


// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)

document.addEventListener("turbolinks:load", function() {
  document.querySelectorAll('[data-toggle="tooltip"]').forEach(dom => {
    dom.tooltip();
  });
});

//BSN.initCallback(document.getElementById('body'));
// Support component names relative to this directory:
var componentRequireContext = require.context("components", true);
var ReactRailsUJS = require("react_ujs");
ReactRailsUJS.useContext(componentRequireContext);
