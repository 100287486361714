import React, { useState } from "react";

const ProductTypeFields = (props) => {
  const [productType, setProductType] = useState(props.productType);
  const [requireName, setRequireName] = useState(props.requireName);
  const [requireKana, setRequireKana] = useState(props.requireKana);
  const [requireAddress, setRequireAddress] = useState(props.requireAddress);
  const [requireFax, setRequireFax] = useState(props.requireFax);
  const [requireDob, setRequireDob] = useState(props.requireDob);
  const [
    requireShippingAddress,
    setRequireShippingAddress,
  ] = useState(props.requireShippingAddress);
  const [stock, setStock] = useState(String(props.stock) || '');
  const [inventoryProductId, setInventoryProductId] = useState(
    props.inventoryProductId || ''
  );
  const [shippingFee, setShippingFee] = useState(String(props.shippingFee) || '');
  const [
    remoteShippingFee,
    setRemoteShippingFee,
  ] = useState(String(props.remoteShippingFee) || '');

  const handleProductTypeChange = (newProductType) => {
    if (newProductType === 'physical') {
      setProductType('physical');
      setRequireName(true);
      setRequireKana(true);
      setRequireAddress(true);
      setRequireShippingAddress(true);
    } else {
      setProductType(newProductType);
    }
  };

  const handleInventoryProductChange = (e) => {
    if (e.target.value !== '') {
      setStock('');
      setInventoryProductId(e.target.value);
    } else {
      setInventoryProductId('');
    }
  }

  return(
    <>
      <div className="form-group">
        <label htmlFor="plan_due_unit">商品タイプ</label>
        <div className="form-inline">
          <div className="form-check mr-2">
            <label>
              <input className="form-radio-input" type="radio" value="digital"
                checked={productType === 'digital'}
                onChange={() => handleProductTypeChange('digital')}
              />
              デジタル商品
            </label>
          </div>
          <div className="form-check mr-2">
            <label>
              <input className="form-radio-input" type="radio" value="physical"
                checked={productType === 'physical'}
                onChange={() => handleProductTypeChange('physical')}
              />
              配送商品
            </label>
          </div>
        </div>
      </div>

      {productType === 'physical' && <div className="row">
        <div className="col-6">
          <div className="form-group">
            <label htmlFor="product_stock">在庫数</label>
            <div className="input-group">
              <input
                className="form-control"
                required={inventoryProductId === ''}
                type="number"
                value={stock}
                id="product_stock"
                onChange={e => setStock(e.target.value)}
                disabled={inventoryProductId !== ''}
              />
              <div className="input-group-append">
                <div className="input-group-text">個</div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-6">
          <label htmlFor="product_stock">他の商品の在庫を使用する場合</label>
          <select className="form-control" onChange={handleInventoryProductChange}>
            <option
              value=''
              selected={inventoryProductId === ''}
            >
              選択してください
            </option>
            {props.inventoryProductOptions.map(ipo => <option
              key={ipo.id}
              value={ipo.id}
              selected={ipo.id === inventoryProductId}
            >
              {ipo.label}
            </option>)}
          </select>
        </div>
      </div>}

      {productType === 'physical' && <div className="form-group">
        <label htmlFor="product_shipping_fee">送料（通常地域）</label>
        <div className="input-group">
          <input
            className="form-control col-sm-5"
            required="required"
            type="number"
            value={shippingFee}
            id="product_shipping_fee"
            onChange={e => setShippingFee(e.target.value)}
          />
          <div className="input-group-append">
            <div className="input-group-text">円</div>
          </div>
        </div>
      </div>}

      {productType === 'physical' && <div className="form-group">
        <label htmlFor="product_remote_shipping_fee">送料（離島地域）</label>
        <div className="input-group">
          <input
            className="form-control col-sm-5"
            required="required"
            type="number"
            value={remoteShippingFee}
            id="product_remote_shipping_fee"
            onChange={e => setRemoteShippingFee(e.target.value)}
          />
          <div className="input-group-append">
            <div className="input-group-text">円</div>
          </div>
        </div>
      </div>}

      <div className="form-group">
        入力必要項目
        <div className="form-inline">
          <div className="form-check mr-2">
            <label>
              <input
                className="form-check-input"
                type="checkbox"
                checked={requireName}
                id="product_require_name"
                onChange={() => {
                  if (productType === 'physical') {
                    alert('配送商品では必ず必須にする必要があります');
                  } else {
                    setRequireName(!requireName);
                  }
                }}
              />
              名前
            </label>
          </div>
          <div className="form-check mr-2">
            <label>
              <input
                className="form-check-input"
                type="checkbox"
                checked={requireKana}
                id="product_require_kana"
                onChange={() => {
                  if (productType === 'physical') {
                    alert('配送商品では必ず必須にする必要があります');
                  } else {
                    setRequireKana(!requireKana);
                  }
                }}
              />
              かな
            </label>
          </div>
          <div className="form-check mr-2">
            <label>
              <input
                className="form-check-input"
                type="checkbox"
                checked={requireAddress}
                id="product_require_address"
                onChange={() => {
                  if (productType === 'physical') {
                    alert('配送商品では必ず必須にする必要があります');
                  } else {
                    setRequireAddress(!requireAddress);
                  }
                }}
              />
              住所
            </label>
          </div>
          <div className="form-check mr-2">
            <label>
              <input
                className="form-check-input"
                type="checkbox"
                checked={requireFax}
                name="product[require_fax]"
                id="product_require_fax"
                onChange={() => setRequireFax(!requireFax)}
              />
              FAX
            </label>
          </div>
          <div className="form-check">
            <label>
              <input
                className="form-check-input"
                type="checkbox"
                checked={requireDob}
                id="product_require_dob"
                onChange={() => setRequireDob(!requireDob)}
              />
              生年月日
            </label>
          </div>
          <div className="form-check">
            <label>
              <input
                className="form-check-input"
                type="checkbox"
                checked={requireShippingAddress}
                id="product_require_shipping_address"
                onChange={() => {
                  if (productType === 'physical') {
                    alert('配送商品では必ず必須にする必要があります');
                  } else {
                    setRequireShippingAddress(!requireShippingAddress);
                  }
                }}
              />
              配送先住所
            </label>
          </div>
        </div>
        <p className="help-text">メールアドレス、電話番号はつねに必須項目です。</p>
      </div>

      <input name="product[product_type]" type="hidden" value={productType} />
      <input name="product[require_name]" type="hidden" value={requireName ? '1' : '0'} />
      <input name="product[require_kana]" type="hidden" value={requireKana ? '1' : '0'} />
      <input name="product[require_address]" type="hidden" value={requireAddress ? '1' : '0'} />
      <input name="product[require_fax]" type="hidden" value={requireFax ? '1' : '0'} />
      <input name="product[require_dob]" type="hidden" value={requireDob ? '1' : '0'} />
      <input
        name="product[require_shipping_address]"
        type="hidden"
        value={requireShippingAddress ? '1' : '0'}
      />
      {productType === 'physical' && <>
        <input name="product[stock]" type="hidden" value={stock} />
        <input name="product[inventory_product_id]" type="hidden" value={inventoryProductId} />
        <input name="product[shipping_fee]" type="hidden" value={shippingFee} />
        <input name="product[remote_shipping_fee]" type="hidden" value={remoteShippingFee} />
      </>}
      {productType !== 'physical' && <>
        <input name="product[stock]" type="hidden" value='' />
        <input name="product[inventory_product_id]" type="hidden" value='' />
        <input name="product[shipping_fee]" type="hidden" value='' />
        <input name="product[remote_shipping_fee]" type="hidden" value='' />
      </>}
      <input name="stock_was" type="hidden" value={props.stock} />
    </>
  );
}

export default ProductTypeFields;
