import React from "react";
import Rails from 'rails-ujs';
import PropTypes from "prop-types";
import Cleave from 'cleave.js/react';
import axios from 'axios';
import qs from 'qs';
import ReviewNotes from './ReviewNotes.js';

class NewCardPayjpFields extends React.Component {
  constructor (props) {
    super(props);
    this.state = {
      nameFirst: '',
      nameLast: '',
      isValidating: false,
      isNameFirstInvalid: false,
      isNameLastInvalid: false,
      errorMessage: null,
      errorCode: null
    }
    this.validate = this.validate.bind(this);
    this.trySubmit = this.trySubmit.bind(this);
    this.createCard = this.createCard.bind(this);

    if(!window.payjp) {
      window.payjp = Payjp(this.props.payjpPublicKey);
    }
  }

  componentDidMount() {
    const payjpElements = window.payjp.elements();

    const style = {
      base: {
        fontSize: '20px',
        color: '#666',
        lineHeight: '32px'
      }
    };

    this.numberElement = payjpElements.create('cardNumber', { style: style });
    this.expiryElement = payjpElements.create('cardExpiry', { style: style });
    this.cvcElement = payjpElements.create('cardCvc', { style: style });

    this.numberElement.mount('#payjp-card-number');
    this.expiryElement.mount('#payjp-card-expiry');
    this.cvcElement.mount('#payjp-card-cvc');

    // Hack-ish way to manipulate Payjp elements
    document.getElementById('payjp-card-number').children[0].style.height = '36px';
    document.getElementById('payjp-card-expiry').children[0].style.height = '36px';
    document.getElementById('payjp-card-cvc').children[0].style.height = '36px';
  }

  validate () {
    let result = true;
    const { nameFirst, nameLast } = this.state;

    if(nameFirst.length === 0) {
      this.setState({isNameFirstInvalid: true})
      result = false;
    } else {
      this.setState({isNameFirstInvalid: false})
    }
    if(nameLast.length === 0) {
      this.setState({isNameLastInvalid: true})
      result = false;
    } else {
      this.setState({isNameLastInvalid: false})
    }

    return result;
  }

  async trySubmit() {
    const { setIsLoading, setNewUserCard, email, tel } = this.props;
    const { nameFirst, nameLast } = this.state;

    let formattedTel = tel;
    if (tel.match(/^0[1-9]\d{8,9}$/)) {
      formattedTel = `+81${formattedTel.slice(1)}`;
    }

    // Show loader and freeze the form
    setIsLoading(true)
    this.setState({ isValidating: true })

    const r = await window.payjp.createToken(this.numberElement, {
      three_d_secure: true,
      card: {
        name: `${nameFirst} ${nameLast}`,
        email: email,
        phone: formattedTel,
      },
    });
    if(r.error) {
      this.setState({
        errorMessage: r.error.message,
        errorCode: r.error.code
      });
      setIsLoading(false);
    } else {
      const createdCard = await this.createCard(r.id);
      if(createdCard) {
        setNewUserCard({ userCardUid: createdCard.uid });
      } else {
        setIsLoading(false);
      }
    }
  }

  async createCard (cardToken) {
    const requestBody = { token: cardToken };
    const headers = { 'X-CSRF-Token': Rails.csrfToken() };
    const { payzSavePath } = this.props;

    try {
      const response = await axios.post(
        payzSavePath, requestBody, { headers: headers, timeout: 25000 }
      );
      if(response.status !== 201) {
        throw new Error(JSON.stringify(response.data));
      }
      return({
        uid: response.data.uid
      });

    } catch (error) {
      this.setState({
        errorMessage: [
          'エラーが発生しました。購入は完了していません。',
          'カード情報を確認してください。すでに登録済みのカードは、再登録できません。',
          '問題が解決しない場合は、カード情報を消してからこの画面のスクリーンショットを取って、カスタマーサポートまでお問い合わせください。'
        ].join(''),
        errorCode: error.message
      });
      return(false);
    }
  }

  render () {
    const { isLoading, backPath, customSubmitBtnLabel, tdsBackUpUrl } = this.props;
    const {
      nameFirst, nameLast, isValidating,
      isNameFirstInvalid, isNameLastInvalid, errorMessage, errorCode
    } = this.state;

    const afterUpdate = () => {isValidating && this.validate()}

    return(
      <React.Fragment>
        <hr />
        <h4>新しいクレジットカードを登録</h4>

        {errorMessage && <div className="alert alert-danger">
          <p>{errorMessage}</p>
          {errorCode && <code>{errorCode}</code>}
        </div>}

        <div className="row">
          <div className="col-12 col-md-10">
            <div className="form-group">
              <label>カード番号</label>
              <div id="payjp-card-number" className="payjp-outer"></div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-12 col-md-6">
            <div className="form-group">
              <label>有効期限</label>
              <div id="payjp-card-expiry" className="payjp-outer"></div>
            </div>
          </div>
          <div className="col-12 col-md-6">
            <div className="form-group">
              <label>セキュリティコード（CVC）</label>
              <div id="payjp-card-cvc" className="payjp-outer"></div>
            </div>
          </div>
        </div>
        <div className="row mb-3">
          <div className="col-6 pr-1 pr-md-3">
            <label>カード名義（名）</label>
            <input type="text"
              value={nameFirst}
              onChange={e => this.setState({nameFirst: e.target.value.replace(/\s/, '')}, afterUpdate)}
              placeholder="TARO"
              className={"form-control form-control-lg" + (isNameFirstInvalid ? ' is-invalid' : '')}
            />
          </div>
          <div className="col-6 pl-1 pl-md-3">
            <label>カード名義（姓）</label>
            <input type="text"
              value={nameLast}
              onChange={e => this.setState({nameLast: e.target.value.replace(/\s/, '')}, afterUpdate)}
              placeholder="YAMADA"
              className={"form-control form-control-lg" + (isNameLastInvalid ? ' is-invalid' : '')}
            />
          </div>
        </div>
        <ReviewNotes tdsBackUpUrl={tdsBackUpUrl} />
        <div className="d-flex justify-content-between">
          {!!backPath &&
            <a className="btn btn-lg btn-outline-secondary" href={backPath} disabled={isLoading}>戻る</a>}
          <button className="btn btn-lg btn-primary" disabled={isLoading} onClick={this.trySubmit}>
            {isLoading ? '処理中です' : (!!customSubmitBtnLabel ? customSubmitBtnLabel : '商品をご購入')}
          </button>
        </div>
      </React.Fragment>
    )
  }
}

export default NewCardPayjpFields
