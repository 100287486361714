import React from "react"
import Rails from 'rails-ujs'

class NameFields extends React.Component {
  render () {
    const { 
      nameLast, nameFirst, setNameLast, setNameFirst, 
      isNameLastInvalid, isNameFirstInvalid 
    } = this.props

    return(
      <div className="form-group row">
        <div className="col-6 pr-0 pr-md-2">
          <label>お名前（姓）</label>
          <input type="text"
            value={nameLast}
            onChange={e => {setNameLast(e.target.value)}}
            placeholder=""
            className={"form-control form-control-lg" + (isNameLastInvalid ? ' is-invalid' : '')}
          />
        </div>
        <div className="col-6 pl-0 pl-md-2">
          <label>（名）</label>
          <input type="text"
            value={nameFirst}
            onChange={e => {setNameFirst(e.target.value)}}
            placeholder=""
            className={"form-control form-control-lg" + (isNameFirstInvalid ? ' is-invalid' : '')}
          />
        </div>
      </div>
    )
  }
}

export default NameFields
