import React from "react"
import ReactSelect from 'react-select'

const dot = (color = '#ccc') => ({
  alignItems: 'center',
  display: 'flex',

  ':before': {
    backgroundColor: color,
    borderRadius: 10,
    content: '" "',
    display: 'block',
    marginRight: 8,
    height: 10,
    width: 10,
  },
});

const tagsToOptions = (tags) => (
  tags.map(t => ({ value: t.id, label: t.label }))
);

class TagMultipleSelectField extends React.Component {
  constructor(props) {
    super(props)

    const selectedTagOptions = tagsToOptions(
      props.tags.filter(t => props.tagIds.includes(t.id))
    )

    this.state = {
      selectedTagOptions: selectedTagOptions
    }
  }

  render() {
    const { tags, fieldsName, placeholder } = this.props;
    const { selectedTagOptions } = this.state;
    const options = tagsToOptions(tags)

    const styles = {
      control: styles => ({ ...styles, 
        backgroundColor: 'white', 
        minWidth: '200px', 
        minHeight: '31px', 
        height: '31px',
        boxShadow: 'none'
      }),
      option: (styles, { data, isDisabled, isFocused, isSelected }) => {
        const { color, colorTone } = tags.find(t => t.id === data.value)
        return { ...styles, ...dot(color) }
      },
      multiValue: (styles, { data }) => {
        const { color } = tags.find(t => t.id === data.value)
        return { ...styles,
          height: '20px',
          lineHeight: '20px',
          alignItems: 'center',
          backgroundColor: color
        };
      },
      multiValueLabel: (styles, { data }) => {
        const { colorTone } = tags.find(t => t.id === data.value)
        return { ...styles,
          color: (colorTone === 'dark' ? 'rgba(255,255,255,0.9)' : 'rgba(0,0,0,0.9)') 
        }
      },
      multiValueRemove: (styles, { data }) => {
        const { colorTone } = tags.find(t => t.id === data.value)
        return { ...styles,
          color: (colorTone === 'dark' ? 'rgba(255,255,255,0.9)' : 'rgba(0,0,0,0.9)'),
          ':hover': {
            backgroundColor: data.color,
          },
        }
      },
      input: styles => ({ ...styles, height: '20px', padding: '0' }),
      valueContainer: styles => ({ ...styles, height: '29px' }),
      indicatorsContainer: styles => ({ ...styles, height: '29px' })
    }

    return(
      <React.Fragment>
        <ReactSelect
          closeMenuOnSelect={false}
          value={selectedTagOptions}
          onChange={(value => {
            this.setState({ selectedTagOptions: (!!value ? value : []) })
          })}
          isMulti
          options={options}
          styles={styles}
          placeholder={placeholder}
        />
        {selectedTagOptions.map(t => (
          <input type="hidden" key={t.value} name={fieldsName} value={t.value} />
        ))}
      </React.Fragment>
    );
  }
}

export default TagMultipleSelectField;