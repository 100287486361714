import React from "react";
import ReactSelect from 'react-select';
import AsyncSelect from 'react-select/async';
import axios from 'axios';

const styles = {
  control: styles => ({ ...styles, 
    height: '48px',
    boxShadow: 'none',
    fontSize: '20px'
  }),
  valueContainer: styles => ({ ...styles, 
    marginLeft: '8px'
  }),
}

const errorStyles = {
  control: styles => ({ ...styles, 
    height: '48px',
    boxShadow: 'none',
    fontSize: '20px',
    borderColor: '#dc3545'
  }),
  valueContainer: styles => ({ ...styles, 
    marginLeft: '8px'
  }),
}

class BankAccountSubForm extends React.Component {
  constructor (props) {
    super(props);
  
    this.state = {
      bankCode: props.bankCode,
      branchCode: props.branchCode,
      branches: []
    };
    this.fetchBranches = this.fetchBranches.bind(this);
  }

  async componentDidMount() {
    if (!!this.props.bankCode) {
      await this.fetchBranches();
    }
  }

  async fetchBranches() {
    const { bankCode } = this.state;

    try {
      const response = await axios.get(
        '/affiliate/bank_account/branches', {
          params: { bank_code: bankCode }
        }
      );
      if (response.status === 200) {
        this.setState({ branches: response.data });
      }
    } catch (error) {
      this.setState({ branches: [] });

      if(error.response.status === 400) {

      } else {
        console.error(error);
      }
    }
  }

  render() {
    const {
      banks, bankCodeFieldName, branchCodeFieldName,
      isBankCodeInvalid, isBranchCodeInvalid,
    } = this.props;

    const {
      bankCode, branchCode, branches
    } = this.state;

    const bankOptions = banks.map(bank => ({
      value: bank.code, label: `${bank.name}（${bank.code}）`
    }));
    const branchOptions = branches.map(branch => ({
      value: branch.code, label: `${branch.name}（${branch.code}）`
    }));
    const selectedBankOption = bankOptions.find(bo => bo.value === bankCode) || null;
    const selectedBranchOption = branchOptions.find(bo => bo.value === branchCode) || null;

    return(
      <React.Fragment>
        <div className="form-group row">
          <div className="col-12 col-sm-8 pr-0 pr-md-2">
            <label>銀行・金融機関</label>
            <ReactSelect
              value={selectedBankOption}
              onChange={(option => {
                this.setState({ bankCode: option.value, branchCode: '' }, () => this.fetchBranches() );
              })}
              options={bankOptions}
              styles={isBankCodeInvalid ? errorStyles : styles}
              placeholder='選択してください'
            />
          </div>
        </div>

        {branches.length > 0 && <div className="form-group row">
          <div className="col-12 col-sm-8 pr-0 pr-md-2">
            <label>支店</label>
            <ReactSelect
              value={selectedBranchOption}
              onChange={(option => {
                this.setState({ branchCode: option.value });
              })}
              options={branchOptions}
              styles={isBranchCodeInvalid ? errorStyles : styles}
              placeholder='選択してください'
            />
          </div>
        </div>}

        <input type="hidden" name={bankCodeFieldName} value={bankCode} />
        <input type="hidden" name={branchCodeFieldName} value={branchCode} />
      </React.Fragment>
    );
  }
}

export default BankAccountSubForm;
