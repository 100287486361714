import React, { useState } from "react";

const TdsFields = (props) => {
  const {
    namespace, tdsBackupOptions
  } = props;
  const [requireTds, setRequireTds] = useState(props.requireTds);
  const [tdsBackupId, setTdsBackupId] = useState(props.tdsBackupId);

  return (
    <>
      <div className="form-group">
        <div className="form-check mr-2">
          <label>
            <input className="form-check-input" type="checkbox"
              checked={requireTds}
              onClick={() => setRequireTds(!requireTds)}
            />
            3Dセキュアを必須にする（試用期間のない初回課金時のみ）
          </label>
        </div>
      </div>

      {requireTds && <div className="form-group">
        <label>3DSバックアップ商品</label>
        <select class="form-control"
          value={tdsBackupId}
          onChange={(e) => setTdsBackupId(e.target.value)}
          required={true}
        >
          <option value="">選択してください</option>
          {tdsBackupOptions.map(option => (
            <option value={option.value} key={option.value}>
              {option.label}
            </option>
          ))}
        </select>
        <p class="help-text">決済時に、エラーの場合この商品を購入するよう促します。</p>
      </div>}

      <input type="hidden" name={`${namespace}[require_tds]`} value={requireTds ? '1' : '0'} />
      {requireTds ? <input
        type="hidden"
        name={`${namespace}[tds_backup_${namespace}_id]`}
        value={tdsBackupId}
      /> : <input
        type="hidden"
        name={`${namespace}[tds_backup_${namespace}_id]`}
        value=""
      />}
    </>
  );
};

export default TdsFields;