import React from "react";
import PropTypes from "prop-types";
import Cleave from 'cleave.js/react';
import ReviewNotes from './ReviewNotes.js';

class NewCardUnivaFields extends React.Component {
  constructor (props) {
    super(props);
    this.state = {
      cardNumber: '',
      expiryMonth: '',
      expiryYear: '',
      cardCvc: '',
      cardBrand: '',
      nameFirst: '',
      nameLast: '',
      isValidating: false,
      univaGetMemberResponse: null,
      univaGetMemberError: null,
      isCardNumberInvalid: false,
      isExpiryMonthInvalid: false,
      isExpiryYearInvalid: false,
      isCardCvcInvalid: false,
      isNameFirstInvalid: false,
      isNameLastInvalid: false,
      errorMessage: null,
      errorCode: null
    }
    this.validate = this.validate.bind(this);
    this.trySubmit = this.trySubmit.bind(this);
  }

  componentDidMount() {
    const {
      setNewUserCard, setIsLoading
    } = this.props;

    // Let's make sense of what UnivaAPI has to say
    const timer = setInterval(() => {
      if(!!window.univaGetMemberWasUpdated) {
        const response = window.univaGetMemberResponse
        const error = window.univaGetMemberError

        this.setState({
          univaGetMemberResponse: response,
          univaGetMemberError: error
        })
        //Test card?
        //if (response.tokenObject && response.resultCode !== "000" && response.resultCode !== 0) {
        if (response.tokenObject) { //TODO
          const maskedCardNo = response.tokenObject.maskedCardNo
          setNewUserCard({
            getewayFid: response.tokenObject.token,
            expiryMonth: response.tokenObject.toBeExpiredAt.substring(0, 2),
            expiryYear: response.tokenObject.toBeExpiredAt.substring(2, 6),
            last4: maskedCardNo.substring(maskedCardNo.length-4, maskedCardNo.length),
            brand: this.state.cardBrand
          })
        } else {
          this.setState({
            errorMessage: [
              'エラーが発生しました。購入は完了していません。カード情報を再入力し、同じ操作を繰り返してください。',
              '問題が解決しない場合は、カード情報を消してからこの画面のスクリーンショットを取って、カスタマーサポートまでお問い合わせください。'
            ].join(''),
            errorCode: `ErrorCode: ${response.resultCode}`
          });
          setIsLoading(false)
        }
        window.univaGetMemberWasUpdated = false
      }
    }, 100)
  }

  validate () {
    let result = true;
    const {
      cardNumber, expiryYear, expiryMonth, cardCvc, nameFirst, nameLast
    } = this.state;

    if(! [14, 15, 16].includes(cardNumber.replace(/\s/g, '').length)) {
      this.setState({isCardNumberInvalid: true})
      result = false;
    } else {
      this.setState({isCardNumberInvalid: false})
    }
    if(expiryYear.length !== 4) {
      this.setState({isExpiryYearInvalid: true})
      result = false;
    } else {
      this.setState({isExpiryYearInvalid: false})
    }
    if(expiryMonth.length !== 2) {
      this.setState({isExpiryMonthInvalid: true})
      result = false;
    } else {
      this.setState({isExpiryMonthInvalid: false})
    }
    if(! [3, 4].includes(cardCvc.replace(' ', '').length)) {
      this.setState({isCardCvcInvalid: true})
      result = false;
    } else {
      this.setState({isCardCvcInvalid: false})
    }
    if(nameFirst.length === 0) {
      this.setState({isNameFirstInvalid: true})
      result = false;
    } else {
      this.setState({isNameFirstInvalid: false})
    }
    if(nameLast.length === 0) {
      this.setState({isNameLastInvalid: true})
      result = false;
    } else {
      this.setState({isNameLastInvalid: false})
    }

    return result;
  }

  trySubmit () {
    const {
      univaStoreId, currentUserUid, email, tel, setIsLoading
    } = this.props;

    // Show loader and freeze the form
    setIsLoading(true)
    this.setState({ isValidating: true })

    if(this.validate()) {
      window.Multipayment.init(univaStoreId); //当社発行の店舗ID
      window.Multipayment.getMember({　//決済フォームより取得した情報
        cardno: this.state.cardNumber.replace(/\s/g, ''), //カード番号
        securitycode: this.state.cardCvc, //セキュリティコード
        expire: `${this.state.expiryMonth}${this.state.expiryYear}`, //カード有効期限
        holderfirstname: this.state.nameFirst, //カードホルダー名
        holderlastname: this.state.nameLast, //カードホルダー姓
        memberid: currentUserUid, //会員番号
        email: email, //メール
        phonenumber: tel //電話番号
      }, 'univaExecMemberPurchase');
    } else {
      setIsLoading(false)
    }
  }

  render () {
    const { isLoading, backPath, customSubmitBtnLabel, tdsBackUpUrl } = this.props;
    const {
      cardNumber, expiryMonth, expiryYear, cardCvc, nameFirst, nameLast, isValidating,
      isCardNumberInvalid, isExpiryMonthInvalid, isExpiryYearInvalid, isCardCvcInvalid,
      isNameFirstInvalid, isNameLastInvalid, errorMessage, errorCode
    } = this.state;

    const currentYear = (new Date()).getFullYear();
    const afterUpdate = () => {isValidating && this.validate()}

    return(
      <React.Fragment>
        <hr />
        <h4>新しいクレジットカードを登録</h4>

        {errorMessage && <div className="alert alert-danger">
          <p>{errorMessage}</p>
          <code>{errorCode}</code>
        </div>}

        <div className="row">
          <div className="col-12 col-md-10">
            <div className="form-group">
              <label>カード番号</label>
              <Cleave placeholder="Enter your credit card number"
                options={{
                  creditCard: true,
                  onCreditCardTypeChanged: type => this.setState({
                    cardBrand: type === 'unknown' ? '' : type
                  })
                }}
                value={cardNumber}
                onChange={e => this.setState({cardNumber: e.target.rawValue}, afterUpdate)}
                placeholder="XXXX XXXX XXXX XXXX"
                className={"form-control form-control-lg" + (isCardNumberInvalid ? ' is-invalid' : '')}
              />
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-12 col-md-6">
            <div className="form-group">
              <label>有効期限</label>
              <div className="input-group">
                <select
                  className={"form-control form-control-lg" + (isExpiryMonthInvalid ? ' is-invalid' : '')}
                  onChange={e => this.setState({expiryMonth: e.target.value}, afterUpdate)}
                  value={!!expiryMonth ? expiryMonth : ''}
                >
                  <option value="" key="">月</option>
                  {[...Array(12).keys()].map(x => String(x+1).padStart(2, '0')).map(
                    month => <option value={month} key={month}>{month}</option>
                  )}
                </select>
                <select
                  className={"form-control form-control-lg" + (isExpiryYearInvalid ? ' is-invalid' : '')}
                  onChange={e => this.setState({expiryYear: e.target.value}, afterUpdate)}
                  value={!!expiryYear ? expiryYear : ''}
                >
                  <option value="" key="">年</option>
                  {[...Array(10).keys()].map(x => x+currentYear).map(
                    year => <option value={year} key={year}>{year}</option>
                  )}
                </select>
              </div>
            </div>
          </div>
          <div className="col-12 col-md-6">
            <div className="form-group">
              <label>セキュリティコード（CVC）</label>
              <input type="password"
                value={cardCvc}
                onChange={e => this.setState({cardCvc: e.target.value}, afterUpdate)}
                placeholder="XXX"
                className={"form-control form-control-lg" + (isCardCvcInvalid ? ' is-invalid' : '')}
              />
            </div>
          </div>
        </div>
        <div className="row mb-3">
          <div className="col-6 pr-1 pr-md-3">
            <label>カード名義（名）</label>
            <input type="text"
              value={nameFirst}
              onChange={e => this.setState({nameFirst: e.target.value.replace(/\s/, '')}, afterUpdate)}
              placeholder="TARO"
              className={"form-control form-control-lg" + (isNameFirstInvalid ? ' is-invalid' : '')}
            />
          </div>
          <div className="col-6 pl-1 pl-md-3">
            <label>カード名義（姓）</label>
            <input type="text"
              value={nameLast}
              onChange={e => this.setState({nameLast: e.target.value.replace(/\s/, '')}, afterUpdate)}
              placeholder="YAMADA"
              className={"form-control form-control-lg" + (isNameLastInvalid ? ' is-invalid' : '')}
            />
          </div>
        </div>
        <ReviewNotes tdsBackUpUrl={tdsBackUpUrl} />
        <div className="d-flex justify-content-between">
          {!!backPath &&
            <a className="btn btn-lg btn-outline-secondary" href={backPath} disabled={isLoading}>戻る</a>}
          <button className="btn btn-lg btn-primary" disabled={isLoading} onClick={this.trySubmit}>
            {isLoading ? '処理中です' : (!!customSubmitBtnLabel ? customSubmitBtnLabel : '商品をご購入')}
          </button>
        </div>
      </React.Fragment>
    )
  }
}

// This get called from React component and write to window, which is called by JSONP
// (which is really ugly but there seems to be no other solid way)
window.univaExecMemberPurchase = function(response) {
  var lang = "ja";
  window.univaGetMemberResponse = response
  window.univaGetMemberError = window.getResultCodeDetail(response.resultCode, lang)
  window.univaGetMemberWasUpdated = true
}

export default NewCardUnivaFields
