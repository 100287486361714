import React from "react";
import qs from 'qs';

const gatewayOptions = {
  univa: "Univa (IPS)",
  aquagates: "Aquagates",
  aquagates_jp: "Aquagates国内ライン",
  payjp: "Pay.jp（物販）",
  aozora: "GMO銀行振込",
  univa_bank: "Univa (IPS) 銀行振込",
  infotop: "infotop",
  stripe: "Stripe",
  square: "Square",
  payjp_digital: "Pay.jp（ﾃﾞｼﾞﾀﾙｺﾝﾃﾝﾂ）",
};

class SummaryGatewaySelector extends React.Component {
  constructor (props) {
    super(props);

    this.state = {
      gateway: props.gateway ? props.gateway : ''
    };
  }

  render() {
    const {
      dateFrom,
      dateTill,
      resolution,
      userCardBrand,
      group,
      productGroupUid,
      membershipGroupUid,
    } = this.props;
    const { gateway } = this.state;

    return(
      <select
        className="form-control"
        value={gateway}
        onChange={(e) => {
          const queryParams = {
            date_from: dateFrom,
            date_till: dateTill,
            resolution: resolution,
            user_card_brand: userCardBrand,
            group: group,
            product_group_uid: productGroupUid,
            membership_group_uid: membershipGroupUid,
            gateway: e.target.value,
          }
          window.location.href = `${window.location.pathname}?${qs.stringify(queryParams)}`
        }}
      >
        <option value="">ｹﾞｰﾄｳｪｲ: 限定しない</option>
        {Object.keys(gatewayOptions).map(gw => {
          return(<option value={gw} key={gw}>{gatewayOptions[gw]}</option>);
        })}
      </select>
    )
  }
}

export default SummaryGatewaySelector;