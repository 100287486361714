import React from "react";
import Rails from 'rails-ujs';
import axios from 'axios';
import ReviewNotes from './ReviewNotes.js';

class NewCardStripeFields extends React.Component {
  constructor (props) {
    super(props);
    this.state = {
      nameFirst: '',
      nameLast: '',
      isValidating: false,
      isNameFirstInvalid: false,
      isNameLastInvalid: false,
      errorMessage: null,
      errorCode: null
    }
    this.validate = this.validate.bind(this);
    this.trySubmit = this.trySubmit.bind(this);
    this.createCard = this.createCard.bind(this);

    this.stripe = Stripe(this.props.stripePublicKey);
  }

  componentDidMount() {
    const stripeElements = this.stripe.elements();

    const style = {
      base: {
        fontSize: '20px',
        color: '#666',
        lineHeight: '32px'
      }
    };

    this.numberElement = stripeElements.create('cardNumber', { style: style });
    this.expiryElement = stripeElements.create('cardExpiry', { style: style });
    this.cvcElement = stripeElements.create('cardCvc', { style: style });

    this.numberElement.mount('#stripe-card-number');
    this.expiryElement.mount('#stripe-card-expiry');
    this.cvcElement.mount('#stripe-card-cvc');
  }

  validate () {
    let result = true;
    const { nameFirst, nameLast } = this.state;

    if(nameFirst.length === 0) {
      this.setState({isNameFirstInvalid: true})
      result = false;
    } else {
      this.setState({isNameFirstInvalid: false})
    }
    if(nameLast.length === 0) {
      this.setState({isNameLastInvalid: true})
      result = false;
    } else {
      this.setState({isNameLastInvalid: false})
    }

    return result;
  }

  async trySubmit() {
    const { setIsLoading, setNewUserCard } = this.props;

    // Show loader and freeze the form
    setIsLoading(true)
    this.setState({ isValidating: true })

    const r = await this.stripe.createToken(this.numberElement);
    if(r.error) {
      this.setState({
        errorMessage: r.error.message,
        errorCode: r.error.code
      });
      setIsLoading(false);
    } else {
      const createdCard = await this.createCard(
        r.token.id, r.token.card.last4,
        r.token.card.exp_month, r.token.card.exp_year, r.token.card.brand
      );
      if(createdCard) {
        setNewUserCard({ userCardUid: createdCard.uid });
      } else {
        setIsLoading(false);
      }
    }
  }

  async createCard (cardToken, cardLast4, cardExpMonth, cardExpYear, cardBrand) {
    const requestBody = {
      token: cardToken, last4: cardLast4, exp_month: cardExpMonth,
      exp_year: cardExpYear, brand: cardBrand, gateway: 'stripe'
    };
    const headers = { 'X-CSRF-Token': Rails.csrfToken() };

    try {
      const response = await axios.post(
        '/stripe_user_cards', requestBody, { headers: headers, timeout: 25000 }
      );
      if(response.status !== 201) {
        throw new Error(JSON.stringify(response.data));
      }
      return({
        uid: response.data.uid
      });

    } catch (error) {
      this.setState({
        errorMessage: [
          'エラーが発生しました。購入は完了していません。',
          'カード情報を確認してください。すでに登録済みのカードは、再登録できません。',
          '問題が解決しない場合は、カード情報を消してからこの画面のスクリーンショットを取って、カスタマーサポートまでお問い合わせください。'
        ].join(''),
        errorCode: error?.response?.data?.message || error.message
      });
      return(false);
    }
  }

  render () {
    const { isLoading, backPath, customSubmitBtnLabel, tdsBackUpUrl } = this.props;
    const {
      nameFirst, nameLast, isValidating,
      isNameFirstInvalid, isNameLastInvalid, errorMessage, errorCode
    } = this.state;

    const afterUpdate = () => {isValidating && this.validate()}

    const stripeWrapperStyle = {};

    const stripeElementStyle = {
      color: '#1a1a1a',
      '::placeholder': { color: '#ccc' },
      fontFamily: '"Hiragino Sans", "Hiragino Kaku Gothic ProN", "Roboto", "Meiryo", sans-serif',
    };

    return(
      <React.Fragment>
        <hr />
        <h4>新しいクレジットカードを登録</h4>

        {errorMessage && <div className="alert alert-danger">
          <p>{errorMessage}</p>
          {errorCode && <code>{errorCode}</code>}
        </div>}

        <div className="row">
          <div className="col-12 col-md-10">
            <div className="form-group">
              <label>カード番号</label>
              <div id="stripe-card-number" className="stripe-outer"></div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-12 col-md-6">
            <div className="form-group">
              <label>有効期限</label>
              <div id="stripe-card-expiry" className="stripe-outer"></div>
            </div>
          </div>
          <div className="col-12 col-md-6">
            <div className="form-group">
              <label>セキュリティコード（CVC）</label>
              <div id="stripe-card-cvc" className="stripe-outer"></div>
            </div>
          </div>
        </div>
        <div className="row mb-3">
          <div className="col-6 pr-1 pr-md-3">
            <label>カード名義（名）</label>
            <input type="text"
              value={nameFirst}
              onChange={e => this.setState({nameFirst: e.target.value.replace(/\s/, '')}, afterUpdate)}
              placeholder="TARO"
              className={"form-control form-control-lg" + (isNameFirstInvalid ? ' is-invalid' : '')}
            />
          </div>
          <div className="col-6 pl-1 pl-md-3">
            <label>カード名義（姓）</label>
            <input type="text"
              value={nameLast}
              onChange={e => this.setState({nameLast: e.target.value.replace(/\s/, '')}, afterUpdate)}
              placeholder="YAMADA"
              className={"form-control form-control-lg" + (isNameLastInvalid ? ' is-invalid' : '')}
            />
          </div>
        </div>
        <ReviewNotes tdsBackUpUrl={tdsBackUpUrl} />
        <div className="d-flex justify-content-between">
          {!!backPath &&
            <a className="btn btn-lg btn-outline-secondary" href={backPath} disabled={isLoading}>戻る</a>}
          <button className="btn btn-lg btn-primary" disabled={isLoading} onClick={this.trySubmit}>
            {isLoading ? '処理中です' : (!!customSubmitBtnLabel ? customSubmitBtnLabel : '商品をご購入')}
          </button>
        </div>
      </React.Fragment>
    )
  }
}

export default NewCardStripeFields;
