import React from "react"
import Rails from 'rails-ujs'

class MembershipPaymentPlanForm extends React.Component {
  constructor (props) {
    super(props)

    this.state = {
      dueUnit: props.dueUnit,
      dueAmount: props.dueAmount,
      dueInterval: props.dueInterval,
      initialPeriod: props.initialPeriod,
      initialRounds: props.initialRounds,
      label: props.label
    }

    this.form = React.createRef()
  }

  render () {
    const { submitPath, isPersisted, deletePath } = this.props
    const { dueUnit, initialRounds, dueAmount, dueInterval, initialPeriod, label } = this.state

    let dueUnitLabel
    if(dueUnit === 'month') {
      dueUnitLabel = 'ヶ月'
    } else if (dueUnit === 'day') {
      dueUnitLabel = '日'
    } else {
      dueUnitLabel = '日/月'
    }

    const initialPeriodDue = Math.max(...initialRounds.map(r => Number(r.due)));

    return(
      <React.Fragment>
        <form ref={this.form} action={submitPath} method="post" onSubmit={(e) => {
          const dueList = initialRounds.map(round => Number(round.due))
          const hasDuplicate = new Set(dueList).size !== dueList.length 
          if(hasDuplicate) {
            alert('課金タイミングに重複があります')
            e.preventDefault()
          }
        }}>
          <input type="hidden" name="authenticity_token" value={Rails.csrfToken()} />
          <input type="hidden" name="plan[due_unit]" value={dueUnit} />
          <input type="hidden" name="plan[label]" value={label ? label : ''} />
          <input type="hidden" name="plan[due_interval]" value={dueInterval ? dueInterval : ''} />
          <input type="hidden" name="plan[due_amount]" value={dueAmount ? dueAmount : ''} />
          <input type="hidden" name="plan[initial_period]" value={initialPeriod ? initialPeriod : '0'} />

          {isPersisted && <input type="hidden" name="_method" value="patch" />}

          <div className="form-group">
            <label htmlFor="plan_due_unit">期間の指定方法</label>
            <div className="form-inline" id="plan_due_unit_radios">
              <div className="form-check mr-2">
                <label>
                  <input className="form-radio-input" type="radio" value="day"
                    checked={dueUnit === 'day'}
                    onChange={(e) => this.setState({ dueUnit: e.target.value })}
                  />
                  日数経過による指定
                </label>
              </div>
              <div className="form-check mr-2">
                <label>
                  <input className="form-radio-input" type="radio" value="month"
                    checked={dueUnit === 'month'}
                    onChange={(e) => this.setState({ dueUnit: e.target.value })}
                  />
                  月経過による指定
                </label>
              </div>
            </div>
          </div>

          <div className="form-group">
            <label htmlFor="label">表示名</label>
            <input className="form-control" type="text"
              value={!!label ? label : ''}
              onChange={(e) => this.setState({ label: e.target.value })}
              placeholder='(例) 6ヶ月払いプラン'
              required
            />
          </div>

          <table className="table table-bordered">
            <thead>
              <tr>
                <th>課金タイミング</th>
                <th>支払金額</th>
              </tr>
            </thead>

            <tbody>
              {initialRounds.map((round, index) => <tr key={`tr-${index}`}>
                <td className="align-middle">{round.due === 0 ? <span>
                  初回<br /><small>購入時または試用期間経過時</small>
                  </span> : <div className="input-group">
                    <input className="form-control" type="number"
                      value={!!round.due ? round.due : ''}
                      onChange={(e) => {
                        let newRounds = initialRounds
                        newRounds[index]['due'] = e.target.value
                        this.setState({ initialRounds: newRounds })
                      }}
                      required min="1"
                    />
                    <div className="input-group-append">
                      <span className="input-group-text">{dueUnitLabel}後</span>
                    </div>
                  </div>}
                </td>
                <td className="align-middle">
                  <div className="input-group">
                    <input className="form-control" type="number"
                      value={!!round.amount ? round.amount : ''}
                      onChange={(e) => {
                        let newRounds = initialRounds
                        newRounds[index]['amount'] = e.target.value
                        this.setState({ initialRounds: newRounds })
                      }}
                      required min="1"
                    />
                    <div className="input-group-append">
                      <span className="input-group-text">円</span>
                    </div>
                  </div>
                </td>
              </tr>)}
              
              {/* Regular refurring period */}
              <tr>
                <td className="align-middle" style={{ minWidth: '200px' }}>
                  {initialRounds.length > 0 ? <div className="input-group">
                    <input className="form-control" type="number"
                      value={!!initialPeriod ? initialPeriod : ''}
                      onChange={(e) => this.setState({ initialPeriod: e.target.value })}
                      required min={initialPeriodDue + 1}
                      
                    />
                    <div className="input-group-append">
                      <span className="input-group-text">{dueUnitLabel}目から</span>
                    </div>
                  </div> : <div>
                    初回から<br /><small>購入時または試用期間経過時</small>
                  </div>}
                </td>
                <td className="align-middle">
                  <div className="input-group">
                    <input className="form-control" type="number"
                      value={!!dueInterval ? dueInterval : ''}
                      onChange={(e) => this.setState({ dueInterval: e.target.value })}
                      required
                    />
                    <div className="input-group-prepend input-group-append">
                      <span className="input-group-text">{dueUnitLabel}ごとに</span>
                    </div>
                    <input className="form-control" type="number"
                      value={!!dueAmount ? dueAmount : ''}
                      onChange={(e) => this.setState({ dueAmount: e.target.value })}
                      required
                    />
                    <div className="input-group-append">
                      <span className="input-group-text">円</span>
                    </div>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>

          {initialRounds.map((round, index) => <React.Fragment key={`${index}`}>
            <input type="hidden" name="due" value={round.due}
              name={`plan[initial_rounds_attributes][${index}][due]`}
            />
            <input type="hidden" name="amount" value={round.amount ? round.amount : ''}
              name={`plan[initial_rounds_attributes][${index}][amount]`}
            />
            <input type="hidden" name="id" value={!!round.id ? round.id : ''}
              name={`plan[initial_rounds_attributes][${index}][id]`}
            />
          </React.Fragment>)}

          <div className='d-flex justify-content-between'>
            {deletePath && <a className="btn btn-outline-danger" 
              data-confirm="本当に分割決済設定を削除しますか？この操作は取り消せません。" 
              rel="nofollow" data-method="delete" 
              href={deletePath}
            >削除</a>}
            <input type="submit" name="commit" value="保存する" className="btn btn-primary" />
          </div>
        </form>
      </React.Fragment>
    )
  }
}

export default MembershipPaymentPlanForm
