import React from "react"
import Rails from 'rails-ujs'

class ProductPartialPaymentPlanForm extends React.Component {
  constructor (props) {
    super(props)

    this.state = {
      dueUnit: props.dueUnit,
      rounds: props.rounds,
      skipInitial: !props.rounds.some(round => round.due === 0)
    }

    this.form = React.createRef()
  }

  render () {
    const { submitPath, isPersisted, deletePath, productPrice } = this.props
    const { dueUnit, rounds, skipInitial } = this.state

    let dueUnitLabel
    if(dueUnit === 'month') {
      dueUnitLabel = 'ヶ月後'
    } else if (dueUnit === 'day') {
      dueUnitLabel = '日後'
    } else {
      dueUnitLabel = '日/月後'
    }

    return(
      <React.Fragment>
        <form ref={this.form} action={submitPath} method="post" onSubmit={(e) => {
          const dueList = rounds.map(round => Number(round.due))
          const hasDuplicate = new Set(dueList).size !== dueList.length 
          if(hasDuplicate) {
            alert('課金タイミングに重複があります')
            e.preventDefault()
          }
        }}>
          <input type="hidden" name="authenticity_token" value={Rails.csrfToken()} />
          <input type="hidden" name="plan[due_unit]" value={dueUnit} />
          {isPersisted && <input type="hidden" name="_method" value="patch" />}

          <div className="form-group">
            <label htmlFor="plan_due_unit">期間の指定方法</label>
            <div className="form-inline" id="plan_due_unit_radios">
              <div className="form-check mr-2">
                <label>
                  <input className="form-radio-input" type="radio" value="day"
                    checked={dueUnit === 'day'}
                    onChange={(e) => this.setState({ dueUnit: e.target.value })}
                  />
                  日数経過による指定
                </label>
              </div>
              <div className="form-check mr-2">
                <label>
                  <input className="form-radio-input" type="radio" value="month"
                    checked={dueUnit === 'month'}
                    onChange={(e) => this.setState({ dueUnit: e.target.value })}
                  />
                  月経過による指定
                </label>
              </div>
            </div>
          </div>

          <div className="form-group">
            <div className="form-inline">
              <div className="form-check mr-2">
                <label>
                  <input className="form-check-input" type="checkbox"
                    checked={skipInitial}
                    onChange={(e) => {
                      if (e.target.checked) {
                        this.setState({
                          skipInitial: true,
                          rounds: rounds.map((round, index) => ({ ...round, ...{ due: index+1 } }))
                        })
                      } else {
                        this.setState({
                          skipInitial: false,
                          rounds: rounds.map((round, index) => ({ ...round, ...{ due: index } }))
                        })
                      }
                    }}
                  />
                  初回に課金を行わない
                </label>
              </div>
            </div>
          </div>

          <table className="table table-bordered">
            <thead>
              <tr>
                <th>課金タイミング</th>
                <th>
                  支払金額 <small>商品金額: {productPrice.toLocaleString()}円</small>
                </th>
              </tr>
            </thead>

            <tbody>
              {rounds.map((round, index) => <tr key={`tr-${index}`}>
                <td className="align-middle">{round.due === 0 ? <span>
                  初回<br /><small>購入時または試用期間経過時</small>
                  </span> : <div className="input-group">
                    <input className="form-control" type="number"
                      value={!!round.due ? round.due : ''}
                      onChange={(e) => {
                        let newRounds = rounds
                        newRounds[index]['due'] = e.target.value
                        this.setState({ rounds: newRounds })
                      }}
                      required min="1"
                    />
                    <div className="input-group-append">
                      <span className="input-group-text">{dueUnitLabel}</span>
                    </div>
                  </div>}
                </td>
                <td className="align-middle">
                  <div className="input-group">
                    <input className="form-control" type="number"
                      value={!!round.amount ? round.amount : ''}
                      onChange={(e) => {
                        let newRounds = rounds
                        newRounds[index]['amount'] = e.target.value
                        this.setState({ rounds: newRounds })
                      }}
                      required min="1"
                    />
                    <div className="input-group-append">
                      <span className="input-group-text">円</span>
                    </div>
                  </div>
                </td>
              </tr>)}
            </tbody>
          </table>

          {rounds.map((round, index) => <React.Fragment key={`${index}`}>
            <input type="hidden" name="due" value={round.due}
              name={`plan[rounds_attributes][${index}][due]`}
            />
            <input type="hidden" name="amount" value={round.amount}
              name={`plan[rounds_attributes][${index}][amount]`}
            />
            <input type="hidden" name="id" value={!!round.id ? round.id : ''}
              name={`plan[rounds_attributes][${index}][id]`}
            />
          </React.Fragment>)}

          <div className='d-flex justify-content-between'>
            {deletePath && <a className="btn btn-outline-danger" 
              data-confirm="本当に分割決済設定を削除しますか？この操作は取り消せません。" 
              rel="nofollow" data-method="delete" 
              href={deletePath}
            >削除</a>}
            <input type="submit" name="commit" value="保存する" className="btn btn-primary" />
          </div>
        </form>
      </React.Fragment>
    )
  }
}

export default ProductPartialPaymentPlanForm
