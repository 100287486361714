import React from "react"
import Rails from 'rails-ujs'

class DobFields extends React.Component {
  render () {
    const {
      year, setYear, isYearInvalid,
      month, setMonth, isMonthInvalid,
      day, setDay, isDayInvalid
    } = this.props

    const now = new Date()

    const yearOptions = [...Array(100)].map((_, i) => {
      const year = now.getFullYear() - i
      return(<option value={year} key={year}>{year}</option>)
    })

    const monthOptions = [...Array(12)].map((_, i) => {
      const month = i + 1
      return(<option value={month} key={month}>{month}</option>)
    })

    const dayOptions = [...Array(31)].map((_, i) => {
      const day = i + 1
      return(<option value={day} key={day}>{day}</option>)
    })

    return(
      <div>
        <label>生年月日</label>
        <div className="form-inline">
          <select 
            onChange={e => {setYear(e.target.value)}}
            value={!!year ? year : ""}
            className={"form-control form-control-lg" + (isYearInvalid ? ' is-invalid' : '')}
          >
            <option value="" key="">年</option>
            {yearOptions}
          </select>

          <select 
            onChange={e => {setMonth(e.target.value)}}
            value={!!month ? month : ""}
            className={"form-control form-control-lg" + (isMonthInvalid ? ' is-invalid' : '')}
          >
            <option value="" key="">月</option>
            {monthOptions}
          </select>

          <select 
            onChange={e => {setDay(e.target.value)}}
            value={!!day ? day : ""}
            className={"form-control form-control-lg" + (isDayInvalid ? ' is-invalid' : '')}
          >
            <option value="" key="">日</option>
            {dayOptions}
          </select>
        </div>
      </div>
    )
  }
}

export default DobFields
