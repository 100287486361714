import React from "react"
import Rails from 'rails-ujs'

class TelField extends React.Component {
  render () {
    const { 
      tel, setTel, isTelInvalid 
    } = this.props

    return(
      <div className="form-group row">
        <div className="col-12 col-lg-8">
          <label>電話番号</label>
          <input type="tel"
            value={tel}
            onChange={e => {setTel(e.target.value.replace(/[^0-9\+]/g, ''))}}
            placeholder="09012345678"
            className={"form-control form-control-lg" + (isTelInvalid ? ' is-invalid' : '')}
          />
          <p className="help-text">ハイフンなし、半角数字のみで入力してください。</p>
        </div>
      </div>
    )
  }
}

export default TelField
