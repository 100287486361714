import React from "react";
import Rails from 'rails-ujs';
import Cleave from 'cleave.js/react';
import axios from 'axios';
import ReviewNotes from './ReviewNotes.js';

class NewCardBpmFields extends React.Component {
  constructor (props) {
    super(props);
    this.state = {
      cardNumber: '',
      expiryMonth: '',
      expiryYear: '',
      cardCvc: '',
      cardBrand: '',
      nameFirst: '',
      nameLast: '',
      isValidating: false,
      aquagatesGetMemberResponse: null,
      aquagatesGetMemberError: null,
      isCardNumberInvalid: false,
      isExpiryMonthInvalid: false,
      isExpiryYearInvalid: false,
      isCardCvcInvalid: false,
      isNameFirstInvalid: false,
      isNameLastInvalid: false,
      errorMessage: null,
      errorCode: null,
    }
    this.validate = this.validate.bind(this);
    this.trySubmit = this.trySubmit.bind(this);
    this.createCard = this.createCard.bind(this);


    TokenPayment.setup(props.bpmApiToken, (error) => {
      if (error) console.log(error);

      this.createTokenPromise = (params) => {
        return new Promise((resolve, reject) => {
          TokenPayment.createCardToken(params, (error, result) => {
            if (result.result_code === '0000') {
              resolve(result);
            } else {
              reject(result);
            }
          });
        });
      }
    });
  }

  validate () {
    let result = true;
    const {
      cardNumber, expiryYear, expiryMonth, cardCvc, nameFirst, nameLast
    } = this.state;

    if(! [14, 15, 16].includes(cardNumber.replace(/\s/g, '').length)) {
      this.setState({isCardNumberInvalid: true})
      result = false;
    } else {
      this.setState({isCardNumberInvalid: false})
    }
    if(expiryYear.length !== 4) {
      this.setState({isExpiryYearInvalid: true})
      result = false;
    } else {
      this.setState({isExpiryYearInvalid: false})
    }
    if(expiryMonth.length !== 2) {
      this.setState({isExpiryMonthInvalid: true})
      result = false;
    } else {
      this.setState({isExpiryMonthInvalid: false})
    }
    if(! [3, 4].includes(cardCvc.replace(' ', '').length)) {
      this.setState({isCardCvcInvalid: true})
      result = false;
    } else {
      this.setState({isCardCvcInvalid: false})
    }
    if(nameFirst.length === 0) {
      this.setState({isNameFirstInvalid: true})
      result = false;
    } else {
      this.setState({isNameFirstInvalid: false})
    }
    if(nameLast.length === 0) {
      this.setState({isNameLastInvalid: true})
      result = false;
    } else {
      this.setState({isNameLastInvalid: false})
    }

    return result;
  }

  async trySubmit(e) {
    const { setIsLoading, setNewUserCard } = this.props;
    const { cardNumber, cardCvc, expiryMonth, expiryYear, nameFirst, nameLast } = this.state;
    // Show loader and freeze the form
    e.preventDefault();
    setIsLoading(true);
    this.setState({ isValidating: true });

    let tokenResult;
    try {
      tokenResult = await this.createTokenPromise({
        number: cardNumber,
        exp_month: expiryMonth,
        exp_year: expiryYear,
        name: `${nameFirst} ${nameLast}`,
        cvc: cardCvc,
      });
    } catch (e) {
      this.setState({
        errorMessage: 'エラーが発生しました',
        errorCode: e.message
      });
      setIsLoading(false);
      return;
    }

    if (tokenResult.result_code === '0000') {
      const createdCard = await this.createCard(tokenResult.cc_token);
      if(createdCard) {
        setNewUserCard({ userCardUid: createdCard.uid });
      } else {
        setIsLoading(false);
      }
    } else {
      this.setState({
        errorMessage: tokenResult.message,
        errorCode: tokenResult.type
      });
      setIsLoading(false);
    }
  }

  async createCard (cardToken) {
    const { cardNumber, expiryMonth, expiryYear, cardBrand } = this.state;
    const requestBody = {
      token: cardToken,
      exp_month: expiryMonth,
      exp_year: expiryYear,
      last4: cardNumber.slice(-4),
      brand: cardBrand,
    };
    const headers = { 'X-CSRF-Token': Rails.csrfToken() };

    try {
      const response = await axios.post(
        '/bpm_user_cards', requestBody, { headers: headers, timeout: 25000 }
      );
      if(response.status !== 201) {
        throw new Error(JSON.stringify(response.data));
      }
      return({
        uid: response.data.uid
      });

    } catch (error) {
      this.setState({
        errorMessage: [
          'エラーが発生しました。購入は完了していません。',
          'カード情報を確認してください。すでに登録済みのカードは、再登録できません。',
          '問題が解決しない場合は、カード情報を消してからこの画面のスクリーンショットを取って、カスタマーサポートまでお問い合わせください。'
        ].join(''),
        errorCode: error?.response?.data?.message || error.message
      });
      return(false);
    }
  }

  render () {
    const { isLoading, backPath, customSubmitBtnLabel, tdsBackUpUrl } = this.props;
    const {
      cardNumber, expiryMonth, expiryYear, cardCvc, nameFirst, nameLast, isValidating,
      isCardNumberInvalid, isExpiryMonthInvalid, isExpiryYearInvalid, isCardCvcInvalid,
      isNameFirstInvalid, isNameLastInvalid, errorMessage, errorCode
    } = this.state;

    const currentYear = (new Date()).getFullYear();
    const afterUpdate = () => {isValidating && this.validate()}
    const isEveryNameFieldValid = nameFirst.length !== 0 && nameLast.length !== 0;

    return(
      <React.Fragment>
        <hr />
        <h4>新しいクレジットカードを登録</h4>
        {errorMessage && <div className="alert alert-danger">
          <p>{errorMessage}</p>
          {errorCode && <code>{errorCode}</code>}
        </div>}

        <div className="row">
          <div className="col-12 col-md-10">
            <div className="form-group">
              <label>カード番号</label>
              <Cleave
                options={{
                  creditCard: true,
                  onCreditCardTypeChanged: type => this.setState({
                    cardBrand: type === 'unknown' ? '' : type
                  })
                }}
                value={cardNumber}
                onChange={e => this.setState({cardNumber: e.target.rawValue}, afterUpdate)}
                placeholder="XXXX XXXX XXXX XXXX"
                className={"form-control form-control-lg" + (isCardNumberInvalid ? ' is-invalid' : '')}
              />
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-12 col-md-6">
            <div className="form-group">
              <label>有効期限</label>
              <div className="input-group">
                <select
                  className={"form-control form-control-lg" + (isExpiryMonthInvalid ? ' is-invalid' : '')}
                  onChange={e => this.setState({expiryMonth: e.target.value}, afterUpdate)}
                  value={!!expiryMonth ? expiryMonth : ''}
                >
                  <option value="" key="">月</option>
                  {[...Array(12).keys()].map(x => String(x+1).padStart(2, '0')).map(
                    month => <option value={month} key={month}>{month}</option>
                  )}
                </select>
                <select
                  className={"form-control form-control-lg" + (isExpiryYearInvalid ? ' is-invalid' : '')}
                  onChange={e => this.setState({expiryYear: e.target.value}, afterUpdate)}
                  value={!!expiryYear ? expiryYear : ''}
                >
                  <option value="" key="">年</option>
                  {[...Array(10).keys()].map(x => x+currentYear).map(
                    year => <option value={year} key={year}>{year}</option>
                  )}
                </select>
              </div>
            </div>
          </div>
          <div className="col-12 col-md-6">
            <div className="form-group">
              <label>セキュリティコード（CVC）</label>
              <input type="password"
                value={cardCvc}
                onChange={e => this.setState({cardCvc: e.target.value}, afterUpdate)}
                placeholder="XXX"
                className={"form-control form-control-lg" + (isCardCvcInvalid ? ' is-invalid' : '')}
              />
            </div>
          </div>
        </div>
        <div className="row mb-3">
          <div className="col-6 pr-1 pr-md-3">
            <label>カード名義（名）</label>
            <input type="text"
              value={nameFirst}
              onChange={e => this.setState({nameFirst: e.target.value.replace(/\s/, '')}, afterUpdate)}
              placeholder="TARO"
              className={"form-control form-control-lg" + (isNameFirstInvalid ? ' is-invalid' : '')}
            />
          </div>
          <div className="col-6 pl-1 pl-md-3">
            <label>カード名義（姓）</label>
            <input type="text"
              value={nameLast}
              onChange={e => this.setState({nameLast: e.target.value.replace(/\s/, '')}, afterUpdate)}
              placeholder="YAMADA"
              className={"form-control form-control-lg" + (isNameLastInvalid ? ' is-invalid' : '')}
            />
          </div>
        </div>
        <ReviewNotes tdsBackUpUrl={tdsBackUpUrl} />
        <div className="d-flex justify-content-between">
          {!!backPath &&
            <a className="btn btn-lg btn-outline-secondary" href={backPath} disabled={isLoading}>戻る</a>}
          <button className="btn btn-lg btn-primary"
            disabled={isLoading || !isEveryNameFieldValid}
            onClick={this.trySubmit}
          >
            {isLoading ? '処理中です' : (!!customSubmitBtnLabel ? customSubmitBtnLabel : '商品をご購入')}
          </button>
        </div>
      </React.Fragment>
    )
  }
}

export default NewCardBpmFields;
