import React from "react";
import Rails from 'rails-ujs';
import axios from 'axios';
import ReviewNotes from './ReviewNotes.js';

class NewCardSquareFields extends React.Component {
  constructor (props) {
    super(props);
    this.state = {
      nameFirst: '',
      nameLast: '',
      isValidating: false,
      isNameFirstInvalid: false,
      isNameLastInvalid: false,
      errorMessage: null,
      errorCode: null
    }
    this.validate = this.validate.bind(this);
    this.trySubmit = this.trySubmit.bind(this);
    this.createCard = this.createCard.bind(this);

    this.squarePayments = Square.payments(props.squareApplicationId, props.squareLocationId)
  }

  async componentDidMount () {
    this.cardElement = await this.squarePayments.card();
    await this.cardElement.attach('#square-card');
  }

  validate () {
    let result = true;
    const { nameFirst, nameLast } = this.state;

    if(nameFirst.length === 0) {
      this.setState({isNameFirstInvalid: true})
      result = false;
    } else {
      this.setState({isNameFirstInvalid: false})
    }
    if(nameLast.length === 0) {
      this.setState({isNameLastInvalid: true})
      result = false;
    } else {
      this.setState({isNameLastInvalid: false})
    }

    return result;
  }

  async trySubmit(e) {
    const { setIsLoading, setNewUserCard } = this.props;
    // Show loader and freeze the form
    e.preventDefault();
    setIsLoading(true);
    this.setState({ isValidating: true });

    let tokenResult;
    try {
      tokenResult = await this.cardElement.tokenize();
    } catch (e) {
      this.setState({
        errorMessage: 'エラーが発生しました',
        errorCode: e.message
      });
      setIsLoading(false);
      return;
    }

    if (tokenResult.status === 'OK') {
      const createdCard = await this.createCard(tokenResult.token);
      if(createdCard) {
        setNewUserCard({ userCardUid: createdCard.uid });
      } else {
        setIsLoading(false);
      }
    } else {
      this.setState({
        errorMessage: tokenResult.message,
        errorCode: tokenResult.type
      });
      setIsLoading(false);
    }

    this.sqPaymentForm.requestCardNonce();
  }

  async createCard (cardToken) {
    const { nameFirst, nameLast } = this.state;
    const requestBody = {
      token: cardToken,
      name_first: nameFirst,
      name_last: nameLast
    };
    const headers = { 'X-CSRF-Token': Rails.csrfToken() };

    try {
      const response = await axios.post(
        '/square_user_cards', requestBody, { headers: headers, timeout: 25000 }
      );
      if(response.status !== 201) {
        throw new Error(JSON.stringify(response.data));
      }
      return({
        uid: response.data.uid
      });

    } catch (error) {
      this.setState({
        errorMessage: [
          'エラーが発生しました。購入は完了していません。',
          'カード情報を確認してください。すでに登録済みのカードは、再登録できません。',
          '問題が解決しない場合は、カード情報を消してからこの画面のスクリーンショットを取って、カスタマーサポートまでお問い合わせください。'
        ].join(''),
        errorCode: error?.response?.data?.message || error.message
      });
      return(false);
    }
  }

  render () {
    const { isLoading, backPath, customSubmitBtnLabel, tdsBackUpUrl } = this.props;
    const {
      nameFirst, nameLast, isValidating,
      isNameFirstInvalid, isNameLastInvalid, errorMessage, errorCode
    } = this.state;

    const afterUpdate = () => {isValidating && this.validate()}
    const isEveryNameFieldValid = nameFirst.length !== 0 && nameLast.length !== 0;

    return(
      <React.Fragment>
        <hr />
        <h4>新しいクレジットカードを登録</h4>

        {errorMessage && <div className="alert alert-danger">
          <p>{errorMessage}</p>
          {errorCode && <code>{errorCode}</code>}
        </div>}

        <div className="row">
          <div className="col-12 col-md-12 mt-3">
            <div id="square-card" />
          </div>
        </div>
        <div className="row mb-3">
          <div className="col-6 pr-1 pr-md-3">
            <label>カード名義（名）</label>
            <input type="text"
              value={nameFirst}
              onChange={e => this.setState({nameFirst: e.target.value.replace(/\s/, '')}, afterUpdate)}
              placeholder="TARO"
              className={"form-control form-control-lg" + (isNameFirstInvalid ? ' is-invalid' : '')}
            />
          </div>
          <div className="col-6 pl-1 pl-md-3">
            <label>カード名義（姓）</label>
            <input type="text"
              value={nameLast}
              onChange={e => this.setState({nameLast: e.target.value.replace(/\s/, '')}, afterUpdate)}
              placeholder="YAMADA"
              className={"form-control form-control-lg" + (isNameLastInvalid ? ' is-invalid' : '')}
            />
          </div>
        </div>
        <ReviewNotes tdsBackUpUrl={tdsBackUpUrl} />
        <div className="d-flex justify-content-between">
          {!!backPath &&
            <a className="btn btn-lg btn-outline-secondary" href={backPath} disabled={isLoading}>戻る</a>}
          <button className="btn btn-lg btn-primary"
            disabled={isLoading || !isEveryNameFieldValid}
            onClick={this.trySubmit}
          >
            {isLoading ? '処理中です' : (!!customSubmitBtnLabel ? customSubmitBtnLabel : '商品をご購入')}
          </button>
        </div>
      </React.Fragment>
    )
  }
}

export default NewCardSquareFields;
