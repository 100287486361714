import React from "react"
import axios from 'axios'

class AddressFields extends React.Component {
  constructor(props) {
    super(props)
    this.fillWithZip = this.fillWithZip.bind(this)
  }

  async fillWithZip(zip) {
    const { setPrefecture, setCity, setAddress1 } = this.props
    try {
      const response = await axios.get(
        '/zipcode/search', {
          params: { zip: zip }
        }
      );
      if (response.status === 200) {
        setPrefecture(response.data.prefecture)
        setCity(response.data.city)
        setAddress1(response.data.address1)
      }
    } catch (error) {
      console.error(error);
    }
  }

  render () {
    const {
      zip, prefecture, city, address1, address2, isRemote,
      setZip, setPrefecture, setCity, setAddress1, setAddress2, setIsRemote,
      isZipInvalid, isPrefectureInvalid, isCityInvalid, isAddress1Invalid, isAddress2Invalid,
      prefectures
    } = this.props

    return(
      <React.Fragment>
        <div className="form-group row">
          <div className="col-12 col-md-6 pr-md-2">
            <label>郵便番号</label>
            <input type="tel"
              value={zip}
              onChange={e => {
                const value = e.target.value.replace(/[^0-9]/g, '')
                setZip(value)
                if (value.length === 7) { this.fillWithZip(value) }
              }}
              placeholder="0000000"
              maxLength="7"
              className={"form-control form-control-lg" + (isZipInvalid ? ' is-invalid' : '')}
            />
          </div>
        </div>
        <div className="form-group row">
          <div className="col-6 pr-0 pr-md-2">
            <label>都道府県</label>
            <select 
              onChange={e => {setPrefecture(e.target.value)}}
              value={!!prefecture ? prefecture : ""}
              className={"form-control form-control-lg" + (isPrefectureInvalid ? ' is-invalid' : '')}
            >
              <option value="" key="">選択してください</option>
              {prefectures.map(
                pref => <option value={pref} key={pref}>{pref}</option>
              )}
            </select>
          </div>
          <div className="col-6 pl-0 pl-md-2">
            <label>市区町村</label>
            <input type="text"
              value={city}
              onChange={e => {setCity(e.target.value)}}
              placeholder=""
              className={"form-control form-control-lg" + (isCityInvalid ? ' is-invalid' : '')}
            />
          </div>
        </div>
        <div className="form-group">
          <label>住所</label>
          <input type="text"
            value={address1}
            onChange={e => {setAddress1(e.target.value)}}
            placeholder=""
            className={"form-control form-control-lg" + (isAddress1Invalid ? ' is-invalid' : '')}
          />
        </div>
        <div className="form-group">
          <label>建物・部屋番号（任意）</label>
          <input type="text"
            value={address2}
            onChange={e => {setAddress2(e.target.value)}}
            placeholder=""
            className={"form-control form-control-lg" + (isAddress2Invalid ? ' is-invalid' : '')}
          />
        </div>
        <div className="form-group">
          <div className="form-check">
            <label>
              <input type="checkbox"
                className="form-check-input"
                checked={isRemote}
                onChange={e => {setIsRemote(e.target.value)}}
              />
              北海道・沖縄・離島地域の配送先に該当します
            </label>
          </div>
        </div>
      </React.Fragment>
    )
  }
}

export default AddressFields
