import React from "react"

class EmailSettingFields extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      isEnabled: props.isEnabled
    }
  }
  render() {
    const { 
      checkboxLabel, checkboxName,
      subject, subjectFieldLabel, subjectFieldName,
      body, bodyFieldLabel, bodyFieldName
    } = this.props;
    const { isEnabled } = this.state;

    return(
      <React.Fragment>
        <div className="form-group">
          <div className="form-inline">
            <div className="form-check mr-2">
              <label>
                <input type="hidden" name={checkboxName} value="0" />
                <input type="checkbox" name={checkboxName} value="1"
                  checked={isEnabled}
                  onChange={e => this.setState({ isEnabled: !!!isEnabled })}
                  className="form-check-input" 
                />
                {checkboxLabel}
              </label>
            </div>
          </div>
        </div>
        <div className="form-group">
          <label>{subjectFieldLabel}</label>
          <input type="text" name={subjectFieldName} 
            defaultValue={subject || ''} className="form-control"
            disabled={!!!isEnabled} required={!!isEnabled}
          />
        </div>
        <div className="form-group">
          <label>{bodyFieldLabel}</label>
          <textarea name={bodyFieldName} rows="8"
            defaultValue={body || ''} className="form-control"
            disabled={!!!isEnabled} required={!!isEnabled}
          />
        </div>
      </React.Fragment>
    )
  }
}

export default EmailSettingFields