import React from "react"
import Rails from 'rails-ujs'

class EmailField extends React.Component {
  render () {
    const { 
      email, setEmail, isEmailInvalid, isReadOnly
    } = this.props

    return(
      <div className="form-group row">
        <div className="col-12 col-lg-10">
          <label>メールアドレス</label>
          <input type="email"
            value={email}
            onChange={e => {setEmail(e.target.value)}}
            placeholder="you@example.com"
            className={"form-control form-control-lg" + (isEmailInvalid ? ' is-invalid' : '')}
            readOnly={isReadOnly}
          />
        </div>
      </div>
    )
  }
}

export default EmailField
