import React from "react";
import qs from 'qs';

class SummaryUserCardSelector extends React.Component {
  constructor (props) {
    super(props);

    this.state = {
      userCardBrand: props.userCardBrand ? props.userCardBrand : ''
    };
  }

  render() {
    const {
      dateFrom,
      dateTill,
      resolution,
      gateway,
      group,
      productGroupUid,
      membershipGroupUid,
    } = this.props;
    const { userCardBrand } = this.state;

    return(
      <select
        className="form-control"
        value={userCardBrand}
        onChange={(e) => {
          const queryParams = {
            date_from: dateFrom,
            date_till: dateTill,
            resolution: resolution,
            gateway: gateway,
            user_card_brand: e.target.value,
            group: group,
            product_group_uid: productGroupUid,
            membership_group_uid: membershipGroupUid,
          }
          window.location.href = `${window.location.pathname}?${qs.stringify(queryParams)}`
        }}
      >
        <option value="">カード: 限定しない</option>
        <option value="visa">Visa</option>
        <option value="mastercard">Mastercard</option>
        <option value="amex">American Express</option>
        <option value="jcb">JCB</option>
        <option value="diners">Diners</option>
      </select>
    )
  }
}

export default SummaryUserCardSelector