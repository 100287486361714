import React from "react";
import Rails from 'rails-ujs';
import NewCardUnivaFields from './NewCardUnivaFields.js';
import NewCardAquagatesFields from './NewCardAquagatesFields.js';
import NewCardPayjpFields from './NewCardPayjpFields.js';
import NewCardStripeFields from './NewCardStripeFields.js';
import NewCardSquareFields from './NewCardSquareFields.js';
import NewCardRappFields from './NewCardRappFields.js';
import NewCardRobotPaymentFields from './NewCardRobotPaymentFields.js';
import NewCardBpmFields from './NewCardBpmFields.js';
import ClipLoader from "react-spinners/ClipLoader";
import ReviewNotes from './ReviewNotes.js';
import PropTypes from "prop-types";

class ReviewSubscriptionForm extends React.Component {
  constructor (props) {
    super(props);

    this.state = {
      paymentPlanUid: (props.paymentPlanOptions.length > 0 ?
        props.paymentPlanOptions[0].uid : undefined
      ),
      userCardUid: (props.userCards.length > 0 ?
        props.userCards[props.userCards.length-1].uid : ''
      ),
      cardExpiryMonth: null,
      cardExpiryYear: null,
      cardLast4: null,
      cardBrand: null,
      cardGatewayFid: null,
      cardOnetimeToken: null,
      cardOnetimeKey: null,
      isLoading: false
    }
    this.form = React.createRef()
  }

  render () {
    const {
      gateway,
      submitPath,
      backPath,
      payByBankTransfer,
      paymentPlanOptions,
      userCards,
      univaStoreId,
      aquagatesClientId,
      aquagatesAccessToken,
      aquagatesJpClientId,
      aquagatesJpAccessToken,
      payjpPublicKey,
      stripePublicKey,
      squareApplicationId,
      squareLocationId,
      payjpDigitalPublicKey,
      rappPublicKey,
      robotPaymentStoreId,
      bpmApiToken,
      currentUserUid,
      email,
      tel,
      shippingZip,
      shippingTel,
      shippingPrefecture,
      shippingCity,
      shippingAddress1,
      shippingAddress2,
      requireTds,
      tdsBackUpUrl,
    } = this.props;

    const {
      paymentPlanUid,
      userCardUid,
      cardExpiryMonth,
      cardExpiryYear,
      cardLast4,
      cardBrand,
      cardGatewayFid,
      cardOnetimeToken,
      cardOnetimeKey,
      isLoading
    } = this.state;

    const paymentPlanOption = paymentPlanOptions.find(option => option.uid === paymentPlanUid);
    const upfrontPaymentRound = paymentPlanOption?.rounds?.find(round => round.isUpfront);

    return (
      <div id="review-subscription-form" className="review-subscription-form">
        <h3>お支払い情報</h3>
        <div className="row">
          <div className="col-12 col-md-10">
            <div className="form-group">
              <label>お支払い回数</label>
              <PaymentPlanSelect
                paymentPlanUid={paymentPlanUid}
                paymentPlanOptions={paymentPlanOptions}
                onChange={(e) => this.setState({paymentPlanUid: e.target.value})}
              />
              {!!paymentPlanUid && <PaymentPlanTable
                paymentPlanOption={paymentPlanOption}
              />}
            </div>

            {!payByBankTransfer && <div className="form-group">
              <label>使用するカードを選択</label>
              <select
                className="form-control form-control-lg"
                onChange={e => this.setState({userCardUid: e.target.value})}
                value={userCardUid}
              >
                {userCards.map(card => <option value={card.uid} key={card.uid}>
                  XXXX-XXXX-XXXX-{card.last4} ({card.expiryMonth}/{card.expiryYear})
                </option>)}
                <option value="">新しいカード</option>
              </select>
            </div>}
          </div>
        </div>

        {userCardUid === '' && gateway === 'univa' && <NewCardUnivaFields
          univaStoreId={univaStoreId}
          currentUserUid={currentUserUid}
          email={email}
          tel={tel}
          isLoading={this.state.isLoading}
          backPath={backPath}
          setNewUserCard={(newAttributes) => {
            this.setState({
              cardGatewayFid: newAttributes.getewayFid,
              cardExpiryMonth: newAttributes.expiryMonth,
              cardExpiryYear: newAttributes.expiryYear,
              cardLast4: newAttributes.last4,
              cardBrand: newAttributes.brand
            }, () => {this.form.current.submit()})
          }}
          setIsLoading={(value) => this.setState({isLoading: value})}
          tdsBackUpUrl={tdsBackUpUrl}
        />}

        {userCardUid === '' && ['aquagates', 'aquagates_jp'].includes(gateway) && <NewCardAquagatesFields
          aquagatesClientId={
            gateway === 'aquagates_jp' ? aquagatesJpClientId : aquagatesClientId
          }
          aquagatesAccessToken={
            gateway === 'aquagates_jp' ? aquagatesJpAccessToken : aquagatesAccessToken
          }
          currentUserUid={currentUserUid}
          email={email}
          tel={tel}
          isLoading={this.state.isLoading}
          backPath={backPath}
          setNewUserCard={(newAttributes) => {
            this.setState({
              cardExpiryMonth: newAttributes.expiryMonth,
              cardExpiryYear: newAttributes.expiryYear,
              cardLast4: newAttributes.last4,
              cardBrand: newAttributes.brand,
              cardOnetimeToken: newAttributes.onetimeToken,
              cardOnetimeKey: newAttributes.onetimeKey
            }, () => {this.form.current.submit()})
          }}
          setIsLoading={(value) => this.setState({isLoading: value})}
          tdsBackUpUrl={tdsBackUpUrl}
        />}

        {userCardUid === '' && ['payjp', 'payjp_digital'].includes(gateway) && <NewCardPayjpFields
          payjpPublicKey={
            gateway === 'payjp_digital' ? payjpDigitalPublicKey : payjpPublicKey
          }
          payzSavePath={
            gateway === 'payjp_digital' ? '/payjp_digital_user_cards' : '/payjp_user_cards'
          }
          currentUserUid={currentUserUid}
          email={email}
          tel={tel}
          isLoading={this.state.isLoading}
          backPath={backPath}
          setNewUserCard={(newAttributes) => {
            this.setState({
              userCardUid: newAttributes.userCardUid
            }, () => {this.form.current.submit()})
          }}
          setIsLoading={(value) => this.setState({isLoading: value})}
          tdsBackUpUrl={tdsBackUpUrl}
          requireTds={requireTds}
        />}

        {userCardUid === '' && gateway === 'stripe' && <NewCardStripeFields
          stripePublicKey={stripePublicKey}
          currentUserUid={currentUserUid}
          email={email}
          tel={tel}
          isLoading={this.state.isLoading}
          backPath={backPath}
          setNewUserCard={(newAttributes) => {
            this.setState({
              userCardUid: newAttributes.userCardUid
            }, () => {this.form.current.submit()})
          }}
          setIsLoading={(value) => this.setState({isLoading: value})}
          tdsBackUpUrl={tdsBackUpUrl}
        />}

        {userCardUid === '' && gateway === 'square' && <NewCardSquareFields
          squareApplicationId={squareApplicationId}
          squareLocationId={squareLocationId}
          currentUserUid={currentUserUid}
          email={email}
          tel={tel}
          isLoading={this.state.isLoading}
          backPath={backPath}
          setNewUserCard={(newAttributes) => {
            this.setState({
              userCardUid: newAttributes.userCardUid
            }, () => {this.form.current.submit()})
          }}
          setIsLoading={(value) => this.setState({isLoading: value})}
          tdsBackUpUrl={tdsBackUpUrl}
        />}

        {userCardUid === '' && gateway === 'rapp' && <NewCardRappFields
          rappPublicKey={rappPublicKey}
          email={email}
          isLoading={this.state.isLoading}
          backPath={backPath}
          setNewUserCard={(newAttributes) => {
            this.setState({
              userCardUid: newAttributes.userCardUid
            }, () => {this.form.current.submit()})
          }}
          setIsLoading={(value) => this.setState({isLoading: value})}
        />}

        {userCardUid === '' && gateway === 'robot_payment' && <NewCardRobotPaymentFields
          robotPaymentStoreId={robotPaymentStoreId}
          isLoading={this.state.isLoading}
          backPath={backPath}
          setNewUserCard={(newAttributes) => {
            this.setState({
              userCardUid: newAttributes.userCardUid
            }, () => {this.form.current.submit()})
          }}
          setIsLoading={(value) => this.setState({isLoading: value})}
          requireTds={requireTds}
          tdsBackUpUrl={tdsBackUpUrl}
          tdsDisplayPrice={upfrontPaymentRound?.amount} //実際には有効性確認だけで、あくまでも3DS表示用
        />}

        {userCardUid === '' && gateway === 'bpm' && <NewCardBpmFields
          bpmApiToken={bpmApiToken}
          isLoading={this.state.isLoading}
          backPath={backPath}
          setNewUserCard={(newAttributes) => {
            this.setState({
              userCardUid: newAttributes.userCardUid
            }, () => {this.form.current.submit()})
          }}
          setIsLoading={(value) => this.setState({isLoading: value})}
        />}

        <form ref={this.form} action={submitPath} method="post">
          <input type="hidden" name="authenticity_token" value={Rails.csrfToken()} />
          <input type="hidden" name="member_id" value={currentUserUid} />
          <input type="hidden" name="email" value={email} />
          <input type="hidden" name="tel" value={tel} />

          <input type="hidden" name="shipping_zip" value={!!shippingZip ? shippingZip : ''} />
          <input type="hidden" name="shipping_prefecture" value={!!shippingPrefecture ? shippingPrefecture : ''} />
          <input type="hidden" name="shipping_city" value={!!shippingCity ? shippingCity : ''} />
          <input type="hidden" name="shipping_address1" value={!!shippingAddress1 ? shippingAddress1 : ''} />
          <input type="hidden" name="shipping_address2" value={!!shippingAddress2 ? shippingAddress2 : ''} />
          <input type="hidden" name="shipping_tel" value={!!shippingTel ? shippingTel : ''} />

          <input type="hidden" name="gateway" value={gateway} />
          <input type="hidden" name="user_card_uid" value={!!userCardUid ? userCardUid : ''} />
          <input type="hidden" name="card_expiry_month" value={!!cardExpiryMonth ? cardExpiryMonth : ''} />
          <input type="hidden" name="card_expiry_year" value={!!cardExpiryYear ? cardExpiryYear : ''} />
          <input type="hidden" name="card_last4" value={!!cardLast4 ? cardLast4 : ''} />
          <input type="hidden" name="card_brand" value={!!cardBrand ? cardBrand : ''} />
          <input type="hidden" name="card_gateway_fid" value={!!cardGatewayFid ? cardGatewayFid : ''} />
          <input type="hidden" name="card_onetime_token" value={!!cardOnetimeToken ? cardOnetimeToken : ''} />
          <input type="hidden" name="card_onetime_key" value={!!cardOnetimeKey ? cardOnetimeKey : ''} />
          <input type="hidden" name="payment_plan_uid" value={paymentPlanUid} />
        </form>

        {(userCardUid !== '' || payByBankTransfer) && <ReviewNotes tdsBackUpUrl={tdsBackUpUrl} />}

        {(userCardUid !== '' || payByBankTransfer) && <div className="d-flex justify-content-between">
          <a className="btn btn-lg btn-outline-secondary" href={backPath} disabled={isLoading}>戻る</a>
          <button className="btn btn-lg btn-primary" disabled={isLoading} onClick={() => {
            this.setState({isLoading: true})
            this.form.current.submit()
          }}>
            {isLoading ? '決済処理中です' : '商品をご購入'}
          </button>
        </div>}

        {isLoading && <div className="spinner">
          <ClipLoader
            size={150}
            color={"#123abc"}
            loading={true}
          />
        </div>}
      </div>
    );
  }
}

class PaymentPlanSelect extends React.Component {
  render() {
    const { 
      paymentPlanUid,
      paymentPlanOptions, 
      onChange
    } = this.props;

    return(
      <select 
        className="form-control form-control-lg col-md-9" 
        onChange={onChange} value={paymentPlanUid}
      >
        {paymentPlanOptions.map(
          opt => <option value={opt.uid} key={opt.uid}>{opt.label}</option>
        )}
      </select>
    );
  }
}

class PaymentPlanTable extends React.Component {
  render() {
    const {
      paymentPlanOption
    } = this.props;

    if(!!!paymentPlanOption){
      return(<React.Fragment></React.Fragment>);
    }

    return(
      <table className="table table-sm table-bordered mt-2">
        <tbody>
          {paymentPlanOption.rounds.map(round => <tr key={round.label}>
            <th scope="row" className="">{round.label}</th>
            <td className="text-center">{round.formattedAmount}</td>
          </tr>)}
        </tbody>
      </table>
    );
  }
}

export default ReviewSubscriptionForm
