import React from "react";
import AsyncSelect from 'react-select/async';
import axios from 'axios';

const affiliateToOption = (affiliate) => (
  { value: affiliate.uid, label: affiliate.label }
);

const fetchMatchedAffiliates = async (inputValue) => {
  try {
    const response = await axios.get('/admin/api/affiliates', {
      params: { q: inputValue }
    });
    return response.data.affiliates.map(affiliateToOption);
  } catch (error) {
    console.error(error);
  }
}

class AffiliateSelectField extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      selectedAffiliateOption: !!props.affiliate ? affiliateToOption(props.affiliate) : undefined
    }
  }

  render() {
    const { 
      fieldName, placeholder, required
    } = this.props;
    const { selectedAffiliateOption } = this.state;

    const styles = {
      control: styles => ({ ...styles, 
        backgroundColor: 'white', 
        minWidth: '260px',
        minHeight: '31px',
        height: '31px',
        boxShadow: 'none',
        fontSize: '14px'
      }),
      indicatorsContainer: styles => ({ ...styles,
        height: '31px'
      })
    }

    return(
      <React.Fragment>
        <AsyncSelect
          loadOptions={fetchMatchedAffiliates}
          onChange={(e) => {this.setState({ selectedAffiliateOption: e })}}
          value={selectedAffiliateOption}
          placeholder={placeholder}
          required
          styles={styles}
          isLoading={false}
          isClearable={true}
        />
        <input
          type="hidden"
          name={fieldName} 
          required={!!required}
          value={
          !!selectedAffiliateOption ? selectedAffiliateOption.value : ''
        } />
      </React.Fragment>
    )
  }
}

export default AffiliateSelectField;