import React from "react"
import { TwitterPicker } from 'react-color'
import RandomColor from 'randomcolor'
import TinyColor from 'tinycolor2'
import Rails from 'rails-ujs';
import PropTypes from "prop-types"

class TagColorPickerField extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      colorValue: props.defaultValue || undefined,
      colorTone: undefined,
      colorValues: RandomColor({ count: 10 })
    }
  }

  render() {
    const { fieldNameValue, fieldNameTone } = this.props;
    const { colorValue, colorTone, colorValues } = this.state;

    return(
      <React.Fragment>
        <TwitterPicker
          colors={colorValues}
          triangle='hide'
          width='530px'
          color={colorValue}
          onChange={(color) => this.setState({ 
            colorValue: color.hex,
            colorTone: TinyColor(color.hex).getLuminance() < 0.4 ? 'dark' : 'light'
          })}
        />
        <input type="hidden" name={fieldNameValue} value={colorValue} />
        <input type="hidden" name={fieldNameTone} value={colorTone} />
      </React.Fragment>
    )
  }
}

export default TagColorPickerField