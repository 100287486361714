import React from "react";
import qs from 'qs';
import moment from 'moment'
import DateRangePicker from 'react-bootstrap-daterangepicker';
import 'bootstrap-daterangepicker/daterangepicker.css';

class SummaryDateRangePicker extends React.Component {
  render() {
    const {
      dateFrom,
      dateTill,
      resolution,
      userCardBrand,
      group,
      productGroupUid,
      membershipGroupUid,
    } = this.props;

    return(
      <DateRangePicker
        initialSettings={{
          startDate: dateFrom, endDate: dateTill,
          opens: 'left',
          locale: { format: 'YYYY/MM/DD' },
          ranges: {
            '過去7日間': [moment().subtract(6, 'days'), moment()],
            '過去30日間': [moment().subtract(29, 'days'), moment()],
            '今月': [moment().startOf('month'), moment().endOf('month')],
            '先月': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')]
          }
        }}
        onApply={(_event, picker) => {
          const queryParams = {
            date_from: picker.startDate.format('YYYY-MM-DD'),
            date_till: picker.endDate.format('YYYY-MM-DD'),
            resolution: resolution,
            user_card_brand: userCardBrand,
            group: group,
            product_group_uid: productGroupUid,
            membership_group_uid: membershipGroupUid,
          }
          window.location.href = `${window.location.pathname}?${qs.stringify(queryParams)}`
        }}
      >
        <input type="text" className="form-control" />
      </DateRangePicker>
    )
  }
}

export default SummaryDateRangePicker
